import { ProfileProperties, IUserData, IUserStatus } from './user.type';

export enum EAuthStatus {
  LOGGED_OUT = 'LOGGED_OUT',
  LOGGED_IN = 'LOGGED_IN',
}

export interface ILoginData {
  username: string;
  password: string;
}

export type AuthState = {
  status: EAuthStatus;
  user: IUserData;
  profile: ProfileProperties;
  loginData: IUserStatus;
  access: any;
};

export type ISignInWithToken = {
  access_token: string;
};
