import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import FilterDashboard from "../dashboard/components/FilterDashboard";
import useFilter from "../../hooks/useFilter";
import useAsset from "../../hooks/useAsset";
import moment from "moment";
import useCompany from "../../hooks/useCompany";
import { generateTimestampArray, toHHMMSS } from "../../helpers/dateUtil";
import { Select, Table } from "antd";
import SeriesSvc from "../../services/SeriesSvc";
import StatusTag from "../../components/StatusTag";
import CardInfo from '../dashboard/components/CardInfo';


const ReportEventEngine: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const { dataFilter } = useFilter();
  const { assets2, fetchAssets } = useAsset();
  const { fetchCompanyData, activeCompany } = useCompany();

  const dataFetchedRef = useRef(false);

  const [eventData, setEventData] = useState<any[]>([]);
  const [summaryData, setSummaryData] = useState<{ [key: string]: any }>({
    portOn: 0,
    portOff: 0,
    centerOn: 0,
    centerOff: 0,
    starboardOn: 0,
    starboardOff: 0,
    averagePortRpm: 0,
    averageCtrRpm: 0,
    averageStbRpm: 0
  });
  const [selectOption, setSelectOption] = useState<'ALL' | 'PORT' | 'CENTER' | 'STARBOARD'>('ALL');

  const currentAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter.massId, assets2]);

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  async function fetchData() {
    const gatewayDevice = currentAsset?.massDevice.find((elm: any) => elm?.devcType === 'gateway');
    const rpmDevice = currentAsset?.massDevice.find((elm: any) => elm?.devcType === 'rpm');

    const start = moment(dataFilter.range.startAt).valueOf() / 1000;
    const end = moment(dataFilter.range.endAt).valueOf() / 1000;

    const gatewayResponse = await SeriesSvc.getAggregatedDataByPeriod('gateway', start, end, {
      params: {
        aggregatedUnit: dataFilter.interval,
        device: [gatewayDevice?.devcUniqueId],
      }
    });

    const rpmResponse = await SeriesSvc.getAggregatedDataByPeriod('rpm', start, end, {
      params: {
        aggregatedUnit: dataFilter.interval,
        device: [rpmDevice?.devcUniqueId],
      }
    });

    const gatewayData = gatewayResponse?.data?.data?.series;
    const rpmData = rpmResponse?.data?.data?.series;

    const portData = rpmData.filter((elm: any) => elm?.type === 'PORT');
    const ctrData = rpmData.filter((elm: any) => elm?.type === 'CENTER');
    const stbData = rpmData.filter((elm: any) => elm?.type === 'STARBOARD');

    const timestamps = generateTimestampArray(start, end, 60);

    const timePortData = timestamps.map((timestamp) => {
      const currPortData = portData.find((elm: any) => elm?.timestamp === timestamp) ?? undefined;
      const currGatewayData = gatewayData.find((elm: any) => elm?.timestamp === timestamp) ?? undefined;

      if (currPortData?.rpm >= 300 && currGatewayData) {
        return {
          timestamp,
          engine: "port",
          status: "on",
          rpm: currPortData?.rpm
        }
      }

      if (currGatewayData && (!currPortData || currPortData?.rpm < 300)) {
        return {
          timestamp,
          engine: "port",
          status: 'off',
        }
      }

      return undefined;
    }).filter(elm => elm !== undefined);

    const timeCtrData = timestamps.map((timestamp) => {
      const currCtrData = ctrData.find((elm: any) => elm?.timestamp === timestamp) ?? undefined;
      const currGatewayData = gatewayData.find((elm: any) => elm?.timestamp === timestamp) ?? undefined;

      if (currCtrData?.rpm >= 300 && currGatewayData) {
        return {
          timestamp,
          engine: "center",
          status: currCtrData && currGatewayData ? "on" : 'off',
          rpm: currCtrData?.rpm
        }
      }

      if (currGatewayData && (!currCtrData || currCtrData?.rpm < 300)) {
        return {
          timestamp,
          engine: "center",
          status: 'off',
        }
      }
      
      return undefined;
    }).filter(elm => elm !== undefined);

    const timeStbData = timestamps.map((timestamp) => {
      const currStbData = stbData.find((elm: any) => elm?.timestamp === timestamp) ?? undefined;
      const currGatewayData = gatewayData.find((elm: any) => elm?.timestamp === timestamp) ?? undefined;

      if (currStbData?.rpm >= 300 && currGatewayData) {
        return {
          timestamp,
          engine: "starboard",
          status: "on",
          rpm: currStbData?.rpm
        }
      }

      if (currGatewayData && (!currStbData || currStbData?.rpm < 300)) {
        return {
          timestamp,
          engine: "starboard",
          status: 'off',
        }
      }

      return undefined;
    }).filter(elm => elm !== undefined);

    /**
     * Generate Event Summary
     */

    const filteredPortRpm = timePortData.filter(elm => elm?.status === 'on');
    const filteredCtrRpm = timeCtrData.filter(elm => elm?.status === 'on');
    const filteredStbRpm = timeStbData.filter(elm => elm?.status === 'on');

    const averagePortRpm = filteredPortRpm.reduce((a: number, b: any) => a + b.rpm, 0) / filteredPortRpm.length;
    const averageCtrRpm = filteredCtrRpm.reduce((a: number, b: any) => a + b.rpm, 0) / filteredCtrRpm.length;
    const averageStbRpm = filteredStbRpm.filter(elm => elm?.status === 'on').reduce((a: number, b: any) => a + b.rpm, 0) / filteredStbRpm.filter(elm => elm?.status === 'on').length;

    const eventSummary = {
      portOn: timePortData.filter(elm => elm?.status === 'on').length,
      portOff: timePortData.filter(elm => elm?.status === 'off').length,
      centerOn: timeCtrData.filter(elm => elm?.status === 'on').length,
      centerOff: timeCtrData.filter(elm => elm?.status === 'off').length,
      starboardOn: timeStbData.filter(elm => elm?.status === 'on').length,
      starboardOff: timeStbData.filter(elm => elm?.status === 'off').length,
      averagePortRpm,
      averageCtrRpm,
      averageStbRpm
    };

    setSummaryData(eventSummary);

    /**
     * Generate Event Log
     */

    const eventPortData: any[] = [];

    for (let i = 0; i < timePortData.length; i++) {
      if (i === 0) {
        eventPortData.push({
          start: timePortData[i]?.timestamp,
          end: timePortData[i]?.timestamp,
          engine: timePortData[i]?.engine,
          status: timePortData[i]?.status
        });
        continue;
      }

      let dataBefore = timePortData[i - 1];
      let dataCurrent = timePortData[i];
      if (dataCurrent?.status === dataBefore?.status) {
        eventPortData[eventPortData.length - 1].end = dataCurrent?.timestamp;
      } else {
        eventPortData.push({
          start: dataCurrent?.timestamp,
          end: dataCurrent?.timestamp,
          engine: dataCurrent?.engine,
          status: dataCurrent?.status
        });
      }
    }

    const eventCtrData: any[] = [];

    for (let i = 0; i < timeCtrData.length; i++) {
      if (i === 0) {
        eventCtrData.push({
          start: timeCtrData[i]?.timestamp,
          end: timeCtrData[i]?.timestamp,
          engine: timeCtrData[i]?.engine,
          status: timeCtrData[i]?.status
        });
        continue;
      }

      let dataBefore = timeCtrData[i - 1];
      let dataCurrent = timeCtrData[i];
      if (dataCurrent?.status === dataBefore?.status) {
        eventCtrData[eventCtrData.length - 1].end = dataCurrent?.timestamp;
      } else {
        eventCtrData.push({
          start: dataCurrent?.timestamp,
          end: dataCurrent?.timestamp,
          engine: dataCurrent?.engine,
          status: dataCurrent?.status
        });
      }
    }

    const eventStbData: any[] = [];

    for (let i = 0; i < timeStbData.length; i++) {
      if (i === 0) {
        eventStbData.push({
          start: timeStbData[i]?.timestamp,
          end: timeStbData[i]?.timestamp,
          engine: timeStbData[i]?.engine,
          status: timeStbData[i]?.status
        });
        continue;
      }

      let dataBefore = timeStbData[i - 1];
      let dataCurrent = timeStbData[i];
      if (dataCurrent?.status === dataBefore?.status) {
        eventStbData[eventStbData.length - 1].end = dataCurrent?.timestamp;
      } else {
        eventStbData.push({
          start: dataCurrent?.timestamp,
          end: dataCurrent?.timestamp,
          engine: dataCurrent?.engine,
          status: dataCurrent?.status
        });
      }
    }

    let eventData = [...eventPortData, ...eventCtrData, ...eventStbData];
    eventData.sort((a, b) => a.start - b.start);

    setEventData(eventData);
  }

  const tableColumn = [
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      width: 180,
      render: (val: any) => moment(val * 1000).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      width: 180,
      render: (val: any) => moment(val * 1000).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Engine',
      dataIndex: 'engine',
      key: 'engine',
      width: 100,
      render: (val: string) => val.toUpperCase(),

    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value: any) =>
        value === 'on' ? (
          <StatusTag isOnline={true} customText='ON' />
        ) : (
          <StatusTag isOnline={false} customText='OFF' />
        ),
    }
  ];

  useEffect(() => {
    try {
      currentAsset && fetchData();
    } catch (error) {
      console.error("failed get operation data", error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  return <React.Fragment>
    <ReportActionContent>
      <FilterDashboard
        isLog
        setURLParams={handleSetURLParams}
        searchParams={searchParams}
      />
    </ReportActionContent>

    <Container>
      <TitleSection>
        <Title>Port Summary</Title>
        <Title>Center Summary</Title>
        <Title>Starboard Summary</Title>
      </TitleSection>
      <SummarySection>
        <CardInfo
          label="AVG. PORT RPM"
          value={String(summaryData.averagePortRpm.toFixed(2))}
          unit={'rpm'}
        />
        <CardInfo
          label="AVG. CENTER RPM"
          value={String(summaryData.averageCtrRpm.toFixed(2))}
          unit={'rpm'}
        />
        <CardInfo
          label="AVG. STARBOARD RPM"
          value={String(summaryData.averageStbRpm.toFixed(2))}
          unit={'rpm'}
        />
        <CardInfo
          label="TOTAL PORT ENGINE ON"
          value={toHHMMSS(summaryData.portOn * 60)}
          unit={'HH:MM:SS'}
        />
        <CardInfo
          label="TOTAL CENTER ENGINE ON"
          value={toHHMMSS(summaryData.centerOn * 60)}
          unit={'HH:MM:SS'}
        />
        <CardInfo
          label="TOTAL STARBOARD ENGINE ON"
          value={toHHMMSS(summaryData.starboardOn * 60)}
          unit={'HH:MM:SS'}
        />

        <CardInfo
          label="TOTAL PORT ENGINE OFF"
          value={toHHMMSS(summaryData.portOff * 60)}
          unit={'HH:MM:SS'}
        />
        <CardInfo
          label="TOTAL CENTER ENGINE OFF"
          value={toHHMMSS(summaryData.centerOff * 60)}
          unit={'HH:MM:SS'}
        />
        <CardInfo
          label="TOTAL STARBOARD ENGINE OFF"
          value={toHHMMSS(summaryData.starboardOff * 60)}
          unit={'HH:MM:SS'}
        />
      </SummarySection>
      <SelectSection>
        <Select
          size='middle'
          defaultValue={selectOption}
          style={{ width: 120, marginLeft: 10 }}
          onChange={(value) => setSelectOption(value)}
          options={[
            { value: 'ALL', label: 'ALL' },
            { value: 'PORT', label: 'PORT' },
            { value: 'CENTER', label: 'CENTER' },
            { value: 'STARBOARD', label: 'STARBOARD' }
          ]}
        />
      </SelectSection>
      <Table
        pagination={false}
        columns={tableColumn}
        dataSource={selectOption === 'ALL' ? eventData : eventData.filter((data) => data.engine === selectOption.toLowerCase())}
      >
      </Table>
    </Container>
  </React.Fragment>;
};

export const ReportActionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: flex-end;
  padding-right: 20px;
`;

const Container = styled.div`
  padding: 20px;
  background-color: #E8ECF3;
`;

const SummarySection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  gap: 10px;
  padding: 10px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
`;

const SelectSection = styled.div`
  margin-bottom: 10px;
`;

const TitleSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  gap: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;  
`
export default ReportEventEngine;