import axios, { AxiosResponse } from 'axios';
import { IResponsePagination } from '../helpers/pagination';
import { IGroupCompanyData } from '../types/groupCompany.type';

export default {
  getGroups(
    config: any
  ): Promise<AxiosResponse<IResponsePagination<IGroupCompanyData>>> {
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/master/group_company`,
      config
    );
  },
  getGroupById(params: any) {
    return axios.get('/master/group_company', { params });
  },
  getAll(params: any) {
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/master/group_company`,
      params
    );
  },
  getById(groupId: string) {
    return axios.get(`/master/group_company/${groupId}`);
  },
  create(data: any) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/master/group_company`,
      data
    );
  },
  update(groupId: string, data: any) {
    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/master/group_company/${groupId}`,
      data
    );
  },
  delete(groupId: string) {
    return axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/master/group_company/${groupId}`
    );
  },
};
