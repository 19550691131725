import { Button, Spin } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import StatusTag from '../../../components/StatusTag';
import {
  degreesToRadians,
  latitudeFormat,
  longitudeFormat,
} from '../../../helpers/map-util';
import { useNavigate } from 'react-router-dom';
import { LoadingWrapper } from '../../report';
import { AssetType } from '../../../types/asset.type';
import { CustomSwitch } from './MapSettings';
import useAsset from '../../../hooks/useAsset';
import { useEffect, useState } from 'react';
import { baseUrl } from '../../../helpers/api';
import useFilter from '../../../hooks/useFilter';
import { toKnot } from '../../../helpers/dateUtil';

interface IProps {
  asset: AssetType;
}

const MapVesselPopup: React.FC<IProps> = ({ asset }) => {
  const navigate = useNavigate();
  const start = moment().startOf('day').valueOf();
  const end = moment().valueOf();
  const [tmpToggle, setTmpToggle] = useState<boolean>(false);
  const {
    loadingCurrAssetTrail,
    fetchCurrentAssetTrail,
    gpsDotTrail,
    handleGPSDotTrail,
    currentDataSource,
  } = useAsset();
  const { setDataFilter } = useFilter();

  function onClickReport() {
    navigate(
      `/report-data-log?start=${start}&end=${end}&mode=period&value=today&aggregatedUnit=HOUR&massId=${asset.massId}&compId=${asset.massCompId}`
    );
  }

  function onClickDashboard() {
    setDataFilter((oldData) => ({ ...oldData, massId: asset.massId }));
    navigate(
      `/dashboard?start=${start}&end=${end}&mode=period&value=today&aggregatedUnit=HOUR&massId=${asset.massId}&compId=${asset.massCompId}`
    );
  }

  const [reRender, setReRender] = useState<number>(0);

  async function handleToggleTrail() {
    try {
      if (asset.massDevice) {
        const currDevcMassId = asset.massDevice.find(
          (item: any) => item.devcType === 'gps'
        )?.devcMassId;

        if (currDevcMassId) {
          await fetchCurrentAssetTrail({
            massId: asset.massId,
            devcMassId: currDevcMassId,
            devcType: 'gps',
          });

          setTmpToggle((old) => !old);
          setReRender((old) => old + 1);
        } else {
          console.log('data devcMassId not found');
        }
      }
    } catch (error) {
      console.error('failed show asset trail', error);
    }
  }

  // useEffect untuk mengrerender ulang popup
  useEffect(() => {}, [reRender]);

  const convertTimestampToDate = (timestamp?: number) => {
    if (timestamp) {
      return moment(timestamp * 1000).format('DD MMM YYYY HH:mm');
    }

    return '-';
  };

  useEffect(() => {
    setTmpToggle(asset.showTrail);
  }, [asset]);

  return (
    <Container className='custom-popup'>
      {false ? (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      ) : (
        <>
          <TitleWrapper>
            <Title>{asset.massName}</Title>
            {/* <X style={{ cursor: "pointer" }} onClick={handleClosePopup} /> */}
          </TitleWrapper>
          <img
            src={
              asset.massImage
                ? `${baseUrl}/${asset.massImage}`
                : '/images/empty-image.png'
            }
          />
          <ContentWrapper>
            <FlexBetween>
              <ItemContent
                label='Last Updated'
                value={convertTimestampToDate(asset.lastDataGps?.timestamp)}
              />
              <Item>
                <div>Vessel Status</div>
                <StatusTag isOnline={asset.isOnline || false} />
              </Item>
              <Item>
                <div>Engine Status</div>
                <StatusTag
                  isOnline={asset.isEngineOn || false}
                  customText={asset.isEngineOn ? 'ON' : 'OFF'}
                />
              </Item>
            </FlexBetween>

            <Separator />

            <GridContent>
              <ItemContent
                label='Latitude'
                value={latitudeFormat(
                  asset.lastDataGps?.latitude as number,
                  asset.lastDataGps?.longitude as number
                )}
              />
              <ItemContent
                label='Longitude'
                value={longitudeFormat(
                  asset.lastDataGps?.latitude as number,
                  asset.lastDataGps?.longitude as number
                )}
              />
              {currentDataSource === 'gsm' ? (
                <ItemContent
                  label='Altitude'
                  value={
                    asset.lastDataGps?.altitude
                      ? (asset.lastDataGps?.altitude as number).toFixed(2)
                      : '-'
                  }
                />
              ) : (
                ''
              )}
            </GridContent>
            {currentDataSource === 'gsm' ? (
              <GridContent>
                <ItemContent
                  label='Heading'
                  value={`${degreesToRadians(
                    asset.lastDataGps?.track as number
                  ).toFixed(2)} °`}
                />
                {/* <ItemContent
                label='Avg. Speed'
                value={
                  asset.dataSummary?.gps?.summary.averageSpeed.toFixed(2) +
                  '  Knot'
                } 
              /> */}
                <ItemContent
                  label='Speed'
                  value={toKnot(asset.lastDataGps?.speed ?? 0) + '  Knot'}
                />
              </GridContent>
            ) : (
              ''
            )}
            <Separator />

            <GridSetting custom>
              <ItemSetting>
                <div>Asset Trail</div>
                <CustomSwitch
                  loading={asset.massId === loadingCurrAssetTrail}
                  onChange={handleToggleTrail}
                  checked={tmpToggle}
                />
              </ItemSetting>
            </GridSetting>
            <AssetInfo>
              {`Last data trail: ${convertTimestampToDate(
                asset.lastDataGps?.timestamp
              )}`}
            </AssetInfo>
            {tmpToggle && currentDataSource === 'gsm' ? (
              <ItemSetting>
                <div>Dot Track</div>
                <CustomSwitch
                  onChange={handleGPSDotTrail}
                  checked={gpsDotTrail === 'active' ? true : false}
                />
              </ItemSetting>
            ) : (
              false
            )}
          </ContentWrapper>

          <ActionWrapper>
            <GridSetting>
              <Button
                onClick={onClickReport}
                size='small'
                type='primary'
                color='#bd1f37'
              >
                View Report
              </Button>
              <Button
                onClick={onClickDashboard}
                size='small'
                type='primary'
                className='btn-accent'
                color='black'
              >
                Dashboard
              </Button>
            </GridSetting>
          </ActionWrapper>
        </>
      )}
    </Container>
  );
};

interface IItemProps {
  label: string;
  value: string;
}

const ItemContent: React.FC<IItemProps> = ({ label, value }) => {
  return (
    <Item>
      <div>{label}</div>
      <p>{value}</p>
    </Item>
  );
};

const AssetInfo = styled.div`
  font-size: 10px;
  opacity: 65%;
`;

const Container = styled.div`
  border-radius: 4px;
  width: 320px;
  overflow: hidden;
  img {
    width: 100%;
    height: 172px;
    object-fit: cover;
  }
`;

const TitleWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayLighten_Light};
  padding: 12px 20px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ContentWrapper = styled.div`
  padding: 10px;
`;

const ActionWrapper = styled.div`
  padding: 10px 20px 10px 20px;
  background-color: ${(props) => props.theme.colors.grayLighten_Light};
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const GridContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Item = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  div {
    color: ${(props) => props.theme.colors.grayDarken_Default};
    margin-bottom: 5px;
  }
  p {
    color: ${(props) => props.theme.colors.black};
    margin: 0;
    font-size: 12px;
  }
`;

const GridSetting = styled.div<{ custom?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) => (props.custom ? '1fr' : '1fr 1fr')};
  gap: 20px;
`;

const ItemSetting = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default MapVesselPopup;
