import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, MenuProps, Space, Spin } from 'antd';
import ReportSummary from './components/ReportSummary';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import ReportBodyVessel from './components/ReportBodyVessel';
import FilterDashboard from '../dashboard/components/FilterDashboard';
import useFilter from '../../hooks/useFilter';
import useAsset from '../../hooks/useAsset';
import { ReportActionContent } from './ReportLog';
import moment from 'moment';
import { generateHourArray } from '../../helpers/dateUtil';
import HChartFuelRunningHour from '../../components/charts/HChartFuelRunningHour';
import HChartMeFuelCons from '../../components/charts/HChartMeFuelCons';
import HChartAEFuelCons from '../../components/charts/HChartAEFuelCons';
import HChartBunker from '../../components/charts/HChartBunker';
import useCompany from '../../hooks/useCompany';
import { intervalOptions } from '../../helpers/map';
import HChartRpmFuelSpeed from '../../components/charts/HChartRpmFuelSpeed';
import DataCompletionInfo from '../../components/DataCompletionInfo';
import { DownOutlined } from '@ant-design/icons';
import AssetSvc from '../../services/AssetSvc';
import SummarySvc from '../../services/SummarySvc';
import { ISummary } from '../../types/summary.type';
import DeviceSvc from '../../services/DeviceSvc';
import { getSource } from '../../helpers/asset';
import PopupDownload from './components/PopupDownload';

interface ILocation {
  chartId: string;
}

const Report: React.FC = () => {
  const { chartId } = useParams<keyof ILocation>() as ILocation;
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { dataFilter } = useFilter();
  const refVesselTracking: any = useRef();
  const refChart: any = useRef();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const {
    assets2,
    fetchAssets,
    fetchAssetsWithLastData,
    isLoadingAssetSummary,
    dataCompletion,
    fetchSummary,
    currentDataSource,
    isLoadingDownload,
    handleOnDownloadPDF,
    currentDataTimezone,
  } = useAsset();
  const { activeCompany, fetchCompanyData } = useCompany();

  const currAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter, assets2]);
  const location = useLocation();
  const currMassId = currAsset ? currAsset.massId : '';
  const isSingleEngine = currAsset?.isSingleEngine || false;
  const isEMS = currAsset?.isEMS || false;

  const downloadItems = useMemo(() => {
    if (currAsset) {
      return [
        {
          key: 'summary',
          label: 'Summary',
        },
        {
          key: 'vessel_tracking',
          label: 'Vessel Tracking',
        },
        {
          key: 'rpm_speed_ae',
          label: currAsset?.isEMS
            ? 'RPM vs Speed vs AE Running Time'
            : 'RPM vs Fuel Cons. vs Speed vs AE Running Time',
        },
        {
          key: 'me_fuel_consumption',
          label: 'ME Fuel Consumption',
          hide: currAsset?.isEMS,
        },
        {
          key: 'me_fuel_consumption_running',
          label: 'ME Fuel Used vs Running Hours',
          hide: currAsset?.isEMS || !currAsset?.isSingleEngine,
        },
        {
          key: 'port_fuel_consumption_running',
          label: 'PORT Fuel Used vs Running Hours',
          hide: currAsset?.isEMS || currAsset?.isSingleEngine,
        },
        {
          key: 'starboard_fuel_consumption_running',
          label: 'STARBOARD Fuel Used vs Running Hours',
          hide: currAsset?.isEMS || currAsset?.isSingleEngine,
        },
        {
          key: 'ae_fuel_consumption ',
          label: 'AE Fuel Consumption',
          hide: currAsset?.isSingleEngine,
        },
        {
          key: 'operation_mode',
          label: 'Operation Mode',
          hide: !(currAsset?.massCompId === "9" || currAsset?.massCompId === "41" || activeCompany?.compGrcpId === "19")
        },
      ].filter((item) => !item.hide);
    }
  }, [currAsset]);

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  const dataFetchedCompRef = useRef(false);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    // if (dataFetchedCompRef.current) return;
    // dataFetchedCompRef.current = true;
    const searchParams = new URLSearchParams(document.location.search);
    const queryMassId = searchParams.get('massId');
    const queryStart = searchParams.get('start');
    const queryEnd = searchParams.get('end');
    const queryAggregatedUnit = searchParams.get('aggregatedUnit');

    fetchAssetsWithLastData({
      aggregatedUnit:
        location.pathname.includes('/report-summary') &&
        searchParams.get('massId') === '62'
          ? 'MINUTE' // pengkondisian untuk kapal citra 02 dan halaman summary agar tidak bisa mengambil data selain minute
          : queryAggregatedUnit
          ? queryAggregatedUnit
          : getSource() === 'satellite'
          ? intervalOptions[1].value
          : intervalOptions[0].value,
      start: queryStart ? queryStart : undefined,
      end: queryEnd ? queryEnd : undefined,
      devcMassId: queryMassId
        ? queryMassId
        : currAsset
        ? currAsset.massId
        : 'initial',
      aggregatedLength: location.pathname.includes('/report-summary')
        ? 1
        : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentDataSource, currentDataTimezone]);

  const currentPath = useMemo(() => {
    if (
      !pathname.includes('report-vessel-tracking') ||
      !currAsset ||
      !currAsset.dataSummary ||
      !currAsset.dataSummary.gps ||
      currAsset.dataSummary.gps.data.length === 0
    ) {
      return [];
    }

    return currAsset.dataSummary.gps.data.map((item) => [
      item.latitude,
      item.longitude,
    ]);
  }, [pathname, currAsset]);

  const navigate = useNavigate();

  const columns = useMemo(() => {
    return generateHourArray(dataFilter.range.startAt, dataFilter.range.endAt);
  }, [dataFilter.range]);

  useEffect(() => {
    if (
      currAsset &&
      !currAsset.isSingleEngine &&
      chartId === 'me-fuel-used-vs-running-hour'
    ) {
      navigate('/report-chart/port-fuel-used-vs-running-hour');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartId, currAsset]);

  const [currDataMaps, setCurrDataMaps] = useState<any>();

  useEffect(() => {
    async function fetchGPSPulse() {
      if(!location.pathname.includes('/report-vessel-tracking')) return;

      const d = new Date();
      console.log("===> ", d.toString(), "Fetching GPS Pulse")

      try {
        const resAssets = await AssetSvc.getAssets({
          params: {
            massId: dataFilter.massId,
          },
        });

        let data =
          resAssets.data.data && resAssets.data.data.length > 0
            ? resAssets.data.data.find(
                (item) => item.massId === dataFilter.massId
              )
            : undefined;

        if (data) {
          const assetHaveMassDevice = data.massDevice;

          if (!assetHaveMassDevice) {

            const deviceResult = await DeviceSvc.getDevices({
              params: {
                findField: 'devcMassId',
                findValue: data.massId,
              },
            });

            data = { ...data, massDevice: deviceResult.data.data };
          }

          const found = data.massDevice?.find(
            (item: any) => item.devcType === 'gps'
          );

          const lastDataAssets = await SummarySvc.getLastData('gps', [found.devcUniqueId]);

          const resDataSummary: ISummary | undefined = await fetchSummary({
            aggregatedUnit: dataFilter.interval,
            aggregatedLength: 1,
            start: String(moment(dataFilter.range.startAt).valueOf()),
            end: String(moment(dataFilter.range.endAt).valueOf()),
            devcMassId: dataFilter.massId,
            devcType: ['gps'],
          });

          // mengambil data saat ini dengan bentuk unixtimestamp di miliseconds
          const now = new Date();
          now.setSeconds(0);
          now.setMilliseconds(0);
          const currentUnixDateHour = now.getTime();

          // mengeset lastgps, jika querystring memilih data saat ini maka mengambil data dari lastDataAssets
          // jika tidak maka mengambil data resDataSummary gps yang terakhir
          const lastGpsData =
            moment(dataFilter.range.endAt).valueOf() !== currentUnixDateHour
              ? resDataSummary?.gps?.data.pop()
              : lastDataAssets[0];

          setCurrDataMaps({
            ...data,
            dataSummary: resDataSummary,
            isOnline:
              moment().valueOf() - lastDataAssets[0].timestamp * 1000 > 600000
                ? false
                : true,
            checked: true,
            lastDataGps: lastGpsData,
            firstDataGps: resDataSummary?.gps?.data
              ? resDataSummary?.gps?.data[0]
              : undefined,
            showTrail: true,
            averageSpeed: resDataSummary?.gps?.summary?.averageSpeed || 0,
            totalCruise: resDataSummary?.gps?.summary?.totalDistance || 0,
          });
        }
      } catch (error) {}
    }

    if (dataFilter.massId) {
      fetchGPSPulse();

      const intervalId = setInterval(() => {
        fetchGPSPulse();
      }, 1000 * 60 * 2);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dataFilter.massId, dataFilter.range.startAt, dataFilter.range.endAt]);

  return (
    <React.Fragment>
      {dataCompletion && (
        <DataCompletionInfo
          loading={false}
          {...dataCompletion}
          pathname={location.pathname}
        />
      )}
      <ReportActionContent>
        <FilterDashboard
          setURLParams={handleSetURLParams}
          searchParams={searchParams}
        />
        <PopupDownload
          options={downloadItems || []}
          loading={isLoadingAssetSummary || isLoadingDownload}
          onDownload={(e) => handleOnDownloadPDF(currAsset, e)}
        />
      </ReportActionContent>

      {isLoadingAssetSummary ? (
        <ContainerLoading>
          <LoadingWrapper>
            <Spin />
            <div>Loading data...</div>
          </LoadingWrapper>
        </ContainerLoading>
      ) : (
        <Content
          customHeight={
            pathname.includes('report-chart')
              ? 'calc(100vh - 144px)'
              : pathname.includes('report-summary') 
              ? 'auto' 
              : undefined
          }
        >
          {/* REPORT SUMMARY */}
          {pathname.includes('report-summary') &&
            currAsset &&
            currAsset.dataSummary && (
              <div>
                <ReportSummary
                  isSingleEngine={isSingleEngine}
                  massId={currMassId}
                  isEMS={isEMS}
                  isHaveOperationMode={currAsset.isHaveOperationMode}
                  isThreeEngine={currAsset.isThreeEngine}
                  data={currAsset?.dataSummary}
                  compId={currAsset.massCompId}
                />
              </div>
            )}

          {/* REPORT VESSEL TRACKING */}
          {pathname.includes('report-vessel-tracking') &&
            currDataMaps &&
            currDataMaps.lastDataGps &&
            currDataMaps.firstDataGps && (
              <ReportBodyVessel
                ref={refVesselTracking}
                massId={currMassId}
                lastDataGPS={currDataMaps.lastDataGps}
                firstDataGPS={currDataMaps.firstDataGps}
                currentPath={currentPath}
                totalCruise={currDataMaps.totalCruise}
                avgSpeed={currDataMaps.averageSpeed}
              />
            )}

          {/* REPORT CHART */}
          {pathname.includes('report-chart') && currAsset && (
            <CardWrapper>
              {chartId === 'rpm-vs-fuel-cons-vs-speed-vs-ae' ||
              chartId === 'rpm-vs-speed-vs-ae' ? (
                <div style={{ height: '75vh' }}>
                  <HChartRpmFuelSpeed
                    ref={refChart}
                    compId={currAsset.massCompId}
                    massId={currAsset.massId}
                    isEMS={currAsset.isEMS}
                    isSingleEngine={currAsset.isSingleEngine}
                    data={currAsset.dataSummary || undefined}
                    columns={columns}
                    isLoading={loadingDownload}
                  />
                </div>
              ) : chartId === 'me-fuel-used-vs-running-hour' ? (
                <HChartFuelRunningHour
                  type='MAINENGINE'
                  ref={refChart}
                  columns={columns}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                />
              ) : chartId === 'port-fuel-used-vs-running-hour' ? (
                <HChartFuelRunningHour
                  type='PORT'
                  ref={refChart}
                  columns={columns}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                />
              ) : chartId === 'stb-fuel-used-vs-running-hour' ? (
                <HChartFuelRunningHour
                  type='STARBOARD'
                  ref={refChart}
                  columns={columns}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                />
              ) : chartId === 'me-fuel-consumption' ? (
                <HChartMeFuelCons
                  ref={refChart}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                  columns={columns}
                />
              ) : chartId === 'ae-fuel-consumption' ? (
                <HChartAEFuelCons
                  ref={refChart}
                  data={currAsset.dataSummary || undefined}
                  columns={columns}
                />
              ) : chartId === 'bunker-in' ? (
                <HChartBunker
                  ref={refChart}
                  data={currAsset.dataSummary || undefined}
                  columns={columns}
                />
              ) : (
                <>No Chart Available</>
              )}
            </CardWrapper>
          )}
        </Content>
      )}
    </React.Fragment>
  );
};
export default Report;

interface IContent {
  customHeight?: string;
}

const CardWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  background-color: white;
`;

const Content = styled.div<IContent>`
  padding: 20px 20px;
  height: ${(props) => props.customHeight || '100%'};
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
`;

export const ContainerLoading = styled.div`
  background: ${(props) => props.theme.colors.grayLighten_Default};
  height: calc(100vh - 144px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 10px;
    opacity: 0.5;
    text-align: center;
  }
`;
