import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../helpers/constant';
import CustomForm from '../CustomForm';
import { DataTableEmpty } from '../../screens/company';
import { baseUrl } from '../../helpers/api';
import { IAssetData } from '../../types/asset.type';

interface Props {
  dataAsset?: IAssetData;
  data: {
    icon: any;
    label: string;
    value: any;
  }[];
  title: {
    label: string;
    value: string;
  };
}

const DetailData: React.FC<Props> = ({ data, title, dataAsset }) => {
  return (
    <React.Fragment>
      {dataAsset ? (
        <AssetInfo>
          <img
            src={
              dataAsset.massImage
                ? `${baseUrl}/${dataAsset.massImage}`
                : '/images/empty-image.png'
            }
            alt='shade'
          />
          <Flex>
            <div>
              <label>Vessel Name</label>
              <p>{title.value}</p>
            </div>
            <div>
              <label>Created at</label>
              <p>{formatDate(dataAsset.massCreatedTime)}</p>
            </div>
            <div>
              <label>System Type</label>
              <Tag style={{ display: 'inline-block' }} color={'#20A1F5'}>
                {dataAsset.massSystemType}
              </Tag>
            </div>
          </Flex>
        </AssetInfo>
      ) : (
        <CompanyInfo>
          <CustomForm label={title.label}>
            <CompanyName>{title.value}</CompanyName>
          </CustomForm>
        </CompanyInfo>
      )}
      <CompanyContact>
        {data.map((item, idx) => (
          <CompantContactItem>
            <ItemLabel>
              {item.icon}
              <span>{item.label}</span>
            </ItemLabel>
            {item.value ? (
              <p>{item.value || 'Not set'}</p>
            ) : (
              <DataTableEmpty>Not set</DataTableEmpty>
            )}
          </CompantContactItem>
        ))}
      </CompanyContact>
    </React.Fragment>
  );
};

export default DetailData;

export const AssetInfo = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0px;
  height: 350px;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
  background-image: url('/images/sample-vessel-1.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  img {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`;

const Flex = styled.div`
  z-index: 10;
  display: grid;
  width: 100%;
  align-items: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr auto;
  color: white;
  padding: 100px 24px 12px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);

  label {
    display: block;
    font-size: 12px;
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  height: 77px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
  background-image: url('/images/background-company.svg');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const CompanyName = styled.div`
  font-size: ${(props) => props.theme.fontSize.title2};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

export const CompanyContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const CompantContactItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 16px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};

  p {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.body2};
  }
`;

export const ItemLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.colors.grayDarken_Default};
`;
