import { Tooltip } from 'antd';
import { useMap, useMapEvent, useMapEvents } from 'react-leaflet';
import styled from 'styled-components';
import useMapConfig from '../../../hooks/useMapConfig';

interface Props {
  showMapSettings: boolean;
  showSummaryTable?: boolean;
  // onClickDrawLine: () => void;
  onCLickClearLine: () => void;
  toggleTableSummary?: () => void;
}

const MapControl: React.FC<Props> = (props) => {
  const map = useMap();
  const {
    setShowModalSettings,
    showModalSettings,
    setIsFullScreen,
    isFullScreen,
    setShowTableSummary,
  } = useMapConfig();

  const increaseMapZoom = () => {
    const currZoom = map.getZoom();
    map.setZoom(currZoom + 1);
  };

  const decreaseMapZoom = () => {
    const currZoom = map.getZoom();
    map.setZoom(currZoom - 1);
  };

  const handleOpenModalSettings = () => {
    setShowModalSettings(!showModalSettings);
  };

  const handleChangeToFullscreen = () => {
    setIsFullScreen(true);
    document.body.requestFullscreen();
  };

  const handleExitFromFullscreen = () => {
    setIsFullScreen(false);
    document.exitFullscreen();
  };

  const handleClickFullScreen = () => {
    if (isFullScreen) {
      handleExitFromFullscreen();
    } else {
      handleChangeToFullscreen();
    }
  };

  return (
    <MapControlContainer>
      {/* Map Settings */}
      {props.showMapSettings && (
        <BtnIconGroup>
          <BtnIcon title='Map Settings' onClick={handleOpenModalSettings}>
            <img src='/images/icon-settings.svg' />
          </BtnIcon>
        </BtnIconGroup>
      )}

      {/* Wind & Wave */}
      {/* <BtnIconGroup>
        <BtnIcon title='Wind Overlay'>
          <img src='/images/icon-wind.svg' />
        </BtnIcon>
        <BtnIcon title='Wind Overlay'>
          <img src='/images/icon-wave.svg' />
        </BtnIcon>
      </BtnIconGroup> */}

      {/* Summary Table */}
      {props.showSummaryTable && (
        <BtnIconGroup>
          <BtnIcon
            title='Summary Table'
            onClick={() => {
              setShowTableSummary((oldVal) => !oldVal);
            }}
          >
            <img src='/images/icon-table.svg' />
          </BtnIcon>
        </BtnIconGroup>
      )}

      {/* Zoom Control */}
      <BtnIconGroup>
        <BtnIcon title='Zoom In Map' onClick={increaseMapZoom}>
          <img src='/images/icon-add.svg' />
        </BtnIcon>
        <BtnIcon title='Zoom Out Map' onClick={decreaseMapZoom}>
          <img src='/images/icon-remove.svg' />
        </BtnIcon>
      </BtnIconGroup>

      {/* Full Screen */}
      <BtnIconGroup>
        <BtnIcon
          title={isFullScreen ? 'Window Mode' : 'Fullscreen Mode'}
          onClick={handleClickFullScreen}
        >
          {isFullScreen ? (
            <img src='/images/icon-minimize.svg' />
          ) : (
            <img src='/images/icon-fullscreen.svg' />
          )}
        </BtnIcon>
      </BtnIconGroup>
    </MapControlContainer>
  );
};

const MapControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  position: absolute;
  z-index: 991;
  bottom: 50px;
  right: 12px;
`;

const BtnIconGroup = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0px 2px rgb(0 0 0 / 20%);
`;

const BtnIcon = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 16px;
  }
`;

export default MapControl;
