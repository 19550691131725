import styled from 'styled-components';
import { TitleCard } from '../../screens/dashboard';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { hChartConfig, hColors } from '../../helpers/chart';
import React from 'react';
import useFilter from '../../hooks/useFilter';
import CardInfo from '../../screens/dashboard/components/CardInfo';
import {
  mergeArraysAndSkipDuplicates,
  roundTimestampsToNearestMinute,
} from '../../helpers/dateUtil';
import { IFlowmeterData, ISummary } from '../../types/summary.type';

highchartsMore(Highcharts);

interface IyAxis {
  color: string;
  text: string;
  opposite?: boolean;
  fixed?: boolean;
}

interface IProps {
  data?: ISummary;
  isDailyReport?: boolean;
  isBMSHome?: boolean;
  columns: number[];
}

interface IFlowmeterDataLog {
  timestamp: number;
  volumeFlowrate: number;
  volumeTotal: number;
  density: number;
  massFlowrate: number;
  massTotal: number;
  temperature: number;
}

const initialFlowmeterDataLog: IFlowmeterDataLog = {
  timestamp: 0,
  volumeFlowrate: 0,
  volumeTotal: 0,
  density: 0,
  massFlowrate: 0,
  massTotal: 0,
  temperature: 0,
};

const yAxisFlowmeterAlt = [
  {
    labels: {
      format: '{value}',
      style: {
        color: hColors.yellow,
      },
    },
    title: {
      text: 'Mass Flow Rate (MT/HR)',
      style: {
        color: hColors.yellow,
      },
    },
    lineColor: hColors.yellow,
    lineWidth: 1,
    tickColor: hColors.yellow,
    tickWidth: 1,
    tickLength: 5,
  },
  {
    labels: {
      format: '{value}',
      style: {
        color: hColors.purple,
      },
    },
    title: {
      text: 'Density (g/cm³)',
      style: {
        color: hColors.purple,
      },
    },
    lineColor: hColors.purple,
    lineWidth: 1,
    tickColor: hColors.purple,
    tickWidth: 1,
    tickLength: 5,
  },
  {
    labels: {
      format: '{value}',
      style: {
        color: hColors.teal,
      },
    },
    title: {
      text: 'Temperature (°C)',
      style: {
        color: hColors.teal,
      },
    },
    lineColor: hColors.teal,
    lineWidth: 1,
    tickColor: hColors.teal,
    tickWidth: 1,
    tickLength: 5,
    opposite: true,
  },
];

const yAxisFlowmeter = [
  {
    color: hColors.purple,
    text: 'Volume Flow Rate',
  },
  {
    color: hColors.blue,
    text: 'Volume Total',
  },
  {
    color: hColors.pink,
    text: 'Density (g/cm³)',
  },
  {
    color: hColors.orange,
    text: 'Mass Flow Rate  (MT/HR)',
    opposite: true,
  },
  {
    color: hColors.green,
    text: 'Mass Total',
    opposite: true,
  },
  {
    color: hColors.darkBlue,
    text: 'Temperature (°C)',
    opposite: true,
  },
];

const HChartBunker = React.forwardRef<any, IProps>(
  ({ data, isDailyReport, isBMSHome }, ref) => {
    const [dataChart, setDataChart] = useState<any>();
    const { dataFilter } = useFilter();
    const [massTotal, setMassTotal] = useState(0);
    const [massFlowrate, setMassFlowrate] = useState(0);
    const [density, setDensity] = useState(0);
    const [volumeTotal, setVolumeTotal] = useState(0);
    const [temperature, setTemperature] = useState(0);

    const constructDataChartFlowmeter = (data: IFlowmeterDataLog[]) => {
      const dataXAxis = data.map((item) =>
        moment(item.timestamp * 1000).format('DD-MM-YY HH:mm:ss')
      );
      const dataSeries: any = [
        {
          name: 'Volume Flow Rate',
          color: hColors.purple,
          type: 'line',
          yAxis: 0,
          data: data.map((item: any) => item.volumeFlowrate),
          tooltip: {},
        },
        {
          name: 'Volume Total',
          color: hColors.blue,
          type: 'line',
          yAxis: 1,
          data: data.map((item: any) => item.volumeTotal),
          tooltip: {
            // valueSuffix: " MT/HR",
            formatter(
              this: Highcharts.AxisLabelsFormatterContextObject,
              ctx: Highcharts.AxisLabelsFormatterContextObject
            ) {
              return typeof this.value === 'number'
                ? this.value.toFixed(2)
                : this.value + ' lala';
            },
          },
        },
        {
          name: 'Density',
          color: hColors.pink,
          type: 'line',
          yAxis: 2,
          data: data.map((item: any) => item.density),
          tooltip: {
            // valueSuffix: " MT/HR",
          },
        },
        {
          name: 'Mass Flow Rate',
          color: hColors.orange,
          type: 'line',
          yAxis: 3,
          data: data.map((item: any) => item.massFlowrate),
          tooltip: {
            // valueSuffix: " L",
          },
        },
        {
          name: 'Mass Total',
          color: hColors.green,
          type: 'line',
          yAxis: 4,
          data: data.map((item: any) => item.massTotal),
          tooltip: {
            // valueSuffix: " L",
          },
        },
        {
          name: 'Temperature',
          color: hColors.darkBlue,
          type: 'line',
          yAxis: 5,
          data: data.map((item: any) => item.temperature),
          tooltip: {
            // valueSuffix: " oC",
          },
        },
      ];

      return {
        dataXAxis,
        dataYAxis: yAxisFlowmeter,
        dataSeries,
        title: 'Bunker',
      };
    };

    const generateYAxis = (data: IyAxis[]) => {
      const yAxis: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions> =
        [];
      for (let idx = 0; idx < data.length; idx++) {
        const item = data[idx];
        yAxis.push({
          labels: {
            style: {
              color: item.color,
            },
          },
          title: {
            text: item.text,
            style: {
              color: item.color,
            },
          },
          lineColor: item.color,
          lineWidth: 1,
          tickColor: item.color,
          tickWidth: 1,
          tickLength: 5,
          opposite: item.opposite,
          min: 0,
        });
      }

      return yAxis;
    };

    const createDataChart = (data: {
      title: any;
      dataXAxis: any;
      dataYAxis: any;
      dataSeries: any;
    }) => {
      let currChartOption = {
        chart: {
          zoomType: 'x',
          scrollablePlotArea: {
            minWidth: 400,
            scrollPositionX: 1,
          },
        },
        title: {
          text: data.title,
          align: 'left',
        },
        subtitle: {
          text: 'Click and drag in the plot area to zoom in',
          align: 'left',
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          ...hChartConfig.plotOptions,
        },
        xAxis: [
          {
            categories: data.dataXAxis,
            crosshair: true,
            scrollbar: {
              enabled: true,
            },
          },
        ],
        tooltip: {
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return (
              this.points &&
              this.points.reduce(function (s, point) {
                return (
                  s + '<br/>' + point.series.name + ': ' + point.y?.toFixed(2)
                );
              }, '<b>' + this.x + '</b>')
            );
          },
          shared: true,
          crosshairs: true,
        },
        yAxis: generateYAxis(data.dataYAxis),
        series: data.dataSeries,
      };

      setDataChart(currChartOption);
    };

    useEffect(() => {
      if (data) {
        const createDataSeries = async () => {
          let newCategories: any[] = mergeArraysAndSkipDuplicates(
            data.flowmeter?.timestamps || []
          ).sort((a, b) => a - b);

          Object.keys(data).forEach((key) => {
            if (
              key === 'flowmeter' &&
              data['flowmeter']?.data &&
              data['flowmeter']?.data.length > 0
            ) {
              const fmDataSeries: IFlowmeterDataLog[] = [];

              if (data[key]) {
                newCategories.forEach((timestamp) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IFlowmeterData) =>
                      data &&
                      data.BUNKER_IN &&
                      timestamp ===
                        roundTimestampsToNearestMinute(
                          data.BUNKER_IN.timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame && isTimestampSame.BUNKER_IN) {
                    fmDataSeries.push({
                      timestamp,
                      volumeFlowrate: isTimestampSame.BUNKER_IN.volumeFlowrate,
                      volumeTotal: isTimestampSame.BUNKER_IN.volumeTotal,
                      density: isTimestampSame.BUNKER_IN.density,
                      massFlowrate: isTimestampSame.BUNKER_IN.massFlowrate,
                      massTotal: isTimestampSame.BUNKER_IN.massTotal,
                      temperature: isTimestampSame.BUNKER_IN.temperature,
                    });
                  } else {
                    fmDataSeries.push({
                      ...initialFlowmeterDataLog,
                      timestamp,
                    });
                  }
                });

                const dataChart = constructDataChartFlowmeter(fmDataSeries);
                createDataChart(dataChart);
              }
            }
          });
        };

        const createDataSummary = () => {
          if (
            data.flowmeter &&
            data.flowmeter.summary &&
            data.flowmeter.summary.BUNKER_IN
          ) {
            setMassTotal(data.flowmeter.summary.BUNKER_IN._totalMassFlow);
            setMassFlowrate(data.flowmeter.summary.BUNKER_IN._averageMassFlowrate);
            setVolumeTotal(data.flowmeter.summary.BUNKER_IN._totalVolumeFlow);
            setTemperature(data.flowmeter.summary.BUNKER_IN._averageTemp);
            setDensity(data.flowmeter.summary.BUNKER_IN._averageDens);
          }
        };

        createDataSummary();
        createDataSeries();
      }
    }, [data]);

    // console.log('CHART BUNKER - raw data: ', data);
    // console.log('CHART BUNKER - data chart:', dataChart);

    return (
      <div ref={ref}>
        {!isDailyReport && <TitleCard title='Bunker In' />}
        <SummaryContainer>
          <CardInfo
            showChart
            style={{ width: '100%' }}
            label='MASS TOTAL'
            value={massTotal?.toFixed(2) ?? '-' }
            unit='MT'
          />
          <CardInfo
            showChart
            style={{ width: '100%' }}
            label='MASS FLOW RATE'
            value={massFlowrate?.toFixed(2) ?? '-'}
            unit='MT/HR'
          />
          <CardInfo
            showChart
            style={{ width: '100%' }}
            label='DENSITY'
            value={density?.toFixed(2) ?? '-'}
            unit='g/cm³'
          />
          <CardInfo
            showChart
            style={{ width: '100%' }}
            label='VOLUME TOTAL'
            value={volumeTotal?.toFixed(2) ?? '-'}
            unit='m³'
          />
          <CardInfo
            showChart
            style={{ width: '100%' }}
            label='TEMPERATURE'
            value={temperature?.toFixed(2) ?? '-'}
            unit='°C'
          />
        </SummaryContainer>
        <div style={{ height: '100%' }}>
          <HighchartsReact
            containerProps={{
              style: {
                height: isBMSHome
                  ? '100%'
                  : isDailyReport || dataFilter.interval === 'HOUR'
                  ? '70%'
                  : '100%',
                paddingTop:
                  isDailyReport || dataFilter.interval === 'HOUR' ? 0 : 20,
                paddingBottom:
                  isDailyReport || dataFilter.interval === 'HOUR' ? 0 : 70,
              },
            }}
            highcharts={Highcharts}
            options={{
              ...dataChart,
              chart: {
                zoomType: 'xy',
                height: isBMSHome ? '650px' : '400px',
              },
            }}
            allowChartUpdate={true}
            immutable={true}
          />
        </div>
      </div>
    );
  }
);

const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  width: 100%;
  padding: 20px;
`;

export default HChartBunker;
function fetchSummarySingleDevice(arg0: {
  aggregatedUnit: any;
  start: string | undefined;
  end: string | undefined;
  devcMassId: any;
  devcType: any[] | undefined;
  aggregatedLength: number;
}): any {
  throw new Error('Function not implemented.');
}
