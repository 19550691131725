import React from "react";
import { AuthState, EAuthStatus } from "../types/auth.type";
import {
  initialUser,
  initialProfile,
  initialUserStatus,
} from "../types/user.type";

type IContext = {
  auth: AuthState;
  setAuth: (value: any) => void;
};

const authContext = React.createContext<IContext>({
  auth: {
    status: EAuthStatus.LOGGED_OUT,
    user: initialUser,
    profile: initialProfile,
    loginData: initialUserStatus,
    access: undefined,
  },
  setAuth: () => {},
});

export default authContext;
