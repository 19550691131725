import { CompanyProps } from './company.type';

export interface IGroupCompanyData {
  grcpAddress: string;
  grcpCity: string;
  grcpCreatedTime: string;
  grcpCreatedUserId: string;
  grcpFax: string;
  grcpId: string;
  grcpName: string;
  grcpNickName: string;
  grcpTelp: string;
  grcpUpdatedTime: string;
  grcpUpdatedUserId: string;
  companies: CompanyProps[];
}

export const initialGroupCompany: IGroupCompanyData = {
  grcpAddress: '',
  grcpCity: '',
  grcpCreatedTime: '',
  grcpCreatedUserId: '',
  grcpFax: '',
  grcpId: '',
  grcpName: '',
  grcpNickName: '',
  grcpTelp: '',
  grcpUpdatedTime: '',
  grcpUpdatedUserId: '',
  companies: [],
};
