import { Tag } from 'antd';
import React from 'react';
import { ChevronRight } from 'react-feather';
import styled from 'styled-components';
import { CompanyProps } from '../../types/company.type';

interface Props {
  data: CompanyProps[];
  onClickItem?: (data: CompanyProps) => void;
}

const DataCompanyList: React.FC<Props> = ({ data, onClickItem }) => {
  return (
    <React.Fragment>
      <HeaderAdditionalInfo>
        <div>Company List</div>
        <span>Total Data: {data.length}</span>
      </HeaderAdditionalInfo>

      <ListCompanyWrapper>
        {data.length === 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0.6,
              paddingTop: 40,
            }}
          >
            Data Empty
          </div>
        ) : (
          data.map((item, idx) => (
            <ListCompanyItem
              key={idx}
              onClick={() => onClickItem && onClickItem(item)}
            >
              {item.compId === '7' ? (
                <img src={'/images/assets/ass.jpg'} />
              ) : item.compId === '8' ? (
                <img src={'/images/assets/kanaka.jpg'} />
              ) : item.compId === '9' ? (
                <img src={'/images/assets/ras.jpg'} />
              ) : item.compId === '10' ? (
                <img src={'/images/assets/aas.jpeg'} />
              ) : item.compId === '14' ? (
                <img src={'/images/assets/mdm.jpg'} />
              ) : item.compId === '24' ? (
                <img src={'/images/assets/meratus.jpeg'} />
              ) : (
                <img src={'/images/logo-not-found.png'} />
              )}
              <div>{item.compName}</div>
              <Flex>
                {/* <span>{item.assets.length} Assets</span> */}
                <Tag
                  style={{ color: 'white' }}
                  color={item.compStatus === '1' ? '#55C95A' : '#FE4545'}
                >
                  {item.compStatus === '1' ? 'Active' : 'Inactive'}
                </Tag>
                {/* <ChevronRight size={14} /> */}
              </Flex>
            </ListCompanyItem>
          ))
        )}
      </ListCompanyWrapper>
    </React.Fragment>
  );
};

export default DataCompanyList;

export const HeaderAdditionalInfo = styled.div`
  background: ${(props) => props.theme.colors.grayLighten_Light};
  padding: 16px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  span {
    font-size: 12px;
    color: ${(props) => props.theme.colors.grayDarken_Dark};
  }
`;

export const ListCompanyWrapper = styled.div`
  padding: 0 20px;
`;

export const ListCompanyItem = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
  align-items: center;
  display: grid;
  gap: 12px;
  /* cursor: pointer; */
  grid-template-columns: 40px 1fr auto;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border: thin solid lightgray;
    overflow: hidden;
    border-radius: 5px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: ${(props) => props.theme.colors.grayDarken_Default};
  /* span {
    font-size: ${(props) => props.theme.fontSize.caption};
  } */
`;
