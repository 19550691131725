import { ReactComponent as IconFuelManagement } from '../assets/images/icon-fuel-management.svg';
import { ReactComponent as IconAssetManagement } from '../assets/images/icon-asset-management.svg';
import { ReactComponent as IconAssetManagementDisabled } from '../assets/images/icon-asset-management-disabled.svg';
import { ReactComponent as IconBunkeringManagement } from '../assets/images/icon-bunkering-management.svg';
import { ReactComponent as IconTankLevelManagement } from '../assets/images/icon-tank-level-management.svg';
import { ReactComponent as IconTankLevelManagementDisabled } from '../assets/images/icon-tank-level-management-disabled.svg';

interface Props {
  id: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const AppIcon: React.FC<Props> = (props) => {
  if (props.id === 'fms') {
    return <IconFuelManagement {...props} />;
  } else if (props.id === 'ams') {
    return props.disabled ? (
      <IconAssetManagementDisabled {...props} />
    ) : (
      <IconAssetManagement {...props} />
    );
  } else if (props.id === 'bms') {
    return <IconBunkeringManagement {...props} />;
  } else if (props.id === 'tlm') {
    return props.disabled ? (
      <IconTankLevelManagementDisabled {...props} />
    ) : (
      <IconTankLevelManagement {...props} />
    );
  } else {
    return <></>;
  }
};

export default AppIcon;
