import formatcoords from 'formatcoords';

export type Map = {
  data: string;
};

export const latitudeFormat = (lat: number|undefined, lng: number|undefined) => {
  if (lat && lng) {
    const res = formatcoords(lat, lng).format("DD MM ss X", {
      latLonSeparator: ", ",
      decimalPlaces: 0,
    });
    return res.split(", ")[0];
  } else {
    return '-';
  }
};

export const longitudeFormat = (lat: number|undefined, lng: number|undefined) => {
  if (lat && lng) {
    const res = formatcoords(lat, lng).format("DD MM ss X", {
      latLonSeparator: ", ",
      decimalPlaces: 0,
    });
    return res.split(", ")[1];
  } else {
    return '-';
  }
};

export const degreesToRadians = (degrees: number) => {
  const pi = Math.PI;
  return degrees * (pi / 180);
};

export function haversineDistance(lat1:number, lon1:number, lat2:number, lon2:number) {
  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const distance = (a:number, b: number) => (Math.PI / 180) * (a - b);
  const R = 6371; // Radius of the Earth in meters

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  const a = Math.sin(dLat / 2) ** 2 + Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in meters
}
