import { AxiosResponse } from 'axios';
import { httpRequest } from '../helpers/api';
import { getToken } from '../helpers/auth';
import { ISignInWithToken } from '../types/auth.type';
import { ISetCompAuth } from '../types/company.type';
import { IUserStatus } from '../types/user.type';

const AuthSvc = {
  signInWithToken(token?: string): Promise<AxiosResponse<ISignInWithToken>> {
    return httpRequest.post(
      `/security/authentication/login?token=` + (token ? token : getToken())
    );
  },
  getUserStatus(): Promise<AxiosResponse<IUserStatus>> {
    return httpRequest.get(`/security/authentication/status`);
  },
  setCompany(compId: string): Promise<AxiosResponse<ISetCompAuth>> {
    return httpRequest.get(`/security/authentication/setcompany/${compId}`);
  },
  LogOut(): Promise<AxiosResponse<any>>{
    return httpRequest.get(`/security/authentication/logout`);
  }
};

export default AuthSvc;
