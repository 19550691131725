import { Tabs, TabsProps } from 'antd';
import MasterReportTemplate from './components/masterReportTemplate';
import {
  ParamKeyValuePair,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import MasterEmailReceivers from './components/masterEmailReceivers';
import MasterTargetAsset from './components/masterTargetAsset';
import useCompany from '../../hooks/useCompany';

const MasterReport = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dataFetchedRef = useRef(false);
  const [activeTab, setActiveTab] = useState<string>();
  const [activeCompId, setActiveCompId] = useState<string>('');
  const { activeCompany } = useCompany();

  const items: TabsProps['items'] = [
    {
      key: 'email_template',
      label: 'Email Template',
      children: (
        <MasterReportTemplate
          activeCompId={activeCompId}
          handleSetSearchParams={handleSetParamCompany}
        />
      ),
    },
    {
      key: 'email_receivers',
      label: 'Email Receivers',
      children: (
        <MasterEmailReceivers
          activeCompId={activeCompId}
          handleSetSearchParams={handleSetParamCompany}
        />
      ),
    },
    {
      key: 'email_target',
      label: 'Email Target by Asset',
      children: (
        <MasterTargetAsset
          activeCompId={activeCompId}
          handleSetSearchParams={handleSetParamCompany}
        />
      ),
    },
  ];

  let { search } = useLocation();
  const query = new URLSearchParams(search);

  function handleSetParamCompany(compId: string) {
    setActiveCompId(compId);
    setSearchParams([
      ['tab', String(activeTab)],
      ['compId', compId],
    ]);
  }

  function handleOnChangeTab(value: string) {
    setActiveTab(value);
    setSearchParams(query);
  }

  useEffect(() => {
    if (activeCompany && activeCompany.compId) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      setActiveCompId(activeCompany.compId);
      setSearchParams([
        ['tab', 'email_template'],
        ['compId', activeCompany.compId],
      ]);
      setActiveTab('email_template');
      // setSelectedCompId(activeCompany.compId);
      // onChangeCompany(activeCompany.compId);
      // props.handleSetSearchParams(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  return (
    <>
      {activeTab && (
        <Tabs
          style={{
            paddingTop: 10,
          }}
          defaultActiveKey={activeTab}
          type='card'
          size={'small'}
          items={items}
          onChange={(value) => handleOnChangeTab(value)}
        />
      )}
    </>
  );
};
export default MasterReport;
