import { AxiosResponse } from "axios";
import { httpRequest } from "../helpers/api";
import { AssetType } from "../types/asset.type";
import { DefaultResponseListProps } from "../types/config.type";

const AssetSvc = {
  getAssets(
    config?: any
  ): Promise<AxiosResponse<DefaultResponseListProps<AssetType>>> {
    return httpRequest.get(`/master/asset`, config);
  },
  getAssetByName(param: any) {
    return httpRequest.get(
      `/master/asset?findField=${param.findField}&findValue=${param.findValue}`
    );
  },
  getAssetByType(param: any) {
    return httpRequest.get(
      `/master/asset?findField=${param.findField}&findValue=${param.findValue}`
    );
  },
  addAsset(data: any) {
    return httpRequest.post(`/master/asset`, data);
  },
  updateAsset(id: any, data: any) {
    return httpRequest.put(`/master/asset/${id}`, data);
  },
  addOrUpdate(id = undefined, data: any) {
    if (id) {
      return this.updateAsset(id, data);
    }
    return this.addAsset(data);
  },
  deleteAsset(id: any) {
    return httpRequest.delete(`/master/asset/${id}`);
  },
};

export default AssetSvc;
