import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  label: string;
  error?: boolean;
  errorMessage?: string;
}

const CustomForm: React.FC<Props> = (props) => {
  return (
    <div>
      <Label error={props.error}>
        {props.label}
        {props.error && props.errorMessage && ` (${props.errorMessage})`}
      </Label>
      {props.children}
    </div>
  );
};

interface ILabel {
  error?: boolean;
}

const Label = styled.div<ILabel>`
  font-size: 12px;
  color: ${(props) =>
    props.error
      ? props.theme.colors.danger_Default
      : props.theme.colors.grayDarken_Default};
  margin-bottom: 3px;
`;

export default CustomForm;
