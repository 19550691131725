import { Drawer, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyProps } from '../../types/company.type';
import { IAssetData } from '../../types/asset.type';
import DataCompanyList from './DataCompanyList';
import DataAssetList from './DataAssetList';
import DataDeviceList from './DataDeviceList';
import { IGroupCompanyData } from '../../types/groupCompany.type';
import {
  AlignLeftOutlined,
  CalendarOutlined,
  CodeOutlined,
  FlagOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  MobileOutlined,
  PhoneOutlined,
  PrinterOutlined,
  UserOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import DetailData from './DetailData';
import styled from 'styled-components';
import { httpRequest } from '../../helpers/api';
import { IResponsePagination } from '../../helpers/pagination';
import { DeviceProps } from '../../types/device.type';
import moment from 'moment';
import { IUserDataWithRole } from '../../screens/user';

interface IProps {
  initialTitle: string;
  open: boolean;
  onClose: () => void;
  initialState: 'company' | 'asset' | 'device' | 'none';
  detailGroup?: IGroupCompanyData;
  detailCompany?: CompanyProps;
  detailAsset?: IAssetData;
  detailDevice?: DeviceProps;
  detailUser?: IUserDataWithRole;
}

const DrawerDetail: React.FC<IProps> = (props) => {
  const defaultParams = {
    page: 1,
    max: 100,
  };
  const [listCompanies, setListCompanies] = useState<CompanyProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const controller = new AbortController();
  const signal = controller.signal;

  const fetchCompanyList = useCallback(async () => {
    setIsLoading(true);
    setListCompanies([]);
    try {
      const response = await httpRequest.get<IResponsePagination<CompanyProps>>(
        `/master/company`,
        {
          params: {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            ...defaultParams,
            findField: 'compGrcpId',
            findValue: props.detailGroup?.grcpId,
          },
          signal: signal,
        }
      );

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setListCompanies(response.data.data);
      }
    } catch (error) {
      console.error('failed get list company', error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listAssets = useMemo(() => {
    if (
      props.initialState === 'asset' &&
      props.detailCompany &&
      props.detailCompany.compAsset
    ) {
      return props.detailCompany.compAsset;
    }
  }, [props.initialState, props.detailCompany]);

  const listDevice = useMemo(() => {
    if (
      props.initialState === 'device' &&
      props.detailAsset &&
      props.detailAsset.massDevice
    ) {
      return props.detailAsset.massDevice;
    }
  }, [props.initialState, props.detailAsset]);

  useEffect(() => {
    if (props.open) {
      if (props.initialState === 'company' && props.detailGroup) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fetchCompanyList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.initialState]);

  const convertDate = (date: any) => {
    return moment(date).format('DD MMM YYYY, HH:mm');
  };

  const mapDataGroupCompany = useMemo(() => {
    if (props.detailGroup) {
      return {
        title: {
          label: 'Group Name',
          value: `(${props.detailGroup.grcpNickName}) - ${props.detailGroup.grcpName}`,
        },
        data: [
          {
            icon: <PhoneOutlined color='#768499' size={16} />,
            label: 'Telephone',
            value: props.detailGroup.grcpTelp,
          },
          {
            icon: <PrinterOutlined color='#768499' size={16} />,
            label: 'Fax',
            value: props.detailGroup.grcpFax,
          },
          {
            icon: <FlagOutlined color='#768499' size={16} />,
            label: 'Address',
            value: props.detailGroup.grcpAddress,
          },
          {
            icon: <HomeOutlined color='#768499' size={16} />,
            label: 'City',
            value: props.detailGroup.grcpCity,
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Created At',
            value: convertDate(props.detailGroup.grcpCreatedTime),
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Updated At',
            value: convertDate(props.detailGroup.grcpUpdatedTime),
          },
          {
            icon: <UserOutlined color='#768499' size={16} />,
            label: 'Created By',
            value: props.detailGroup.grcpCreatedUserId,
          },
        ],
      };
    }
  }, [props.detailGroup]);

  const mapDataCompany = useMemo(() => {
    if (props.detailCompany) {
      return {
        title: {
          label: 'Company Name',
          value: `${props.detailCompany.compName}`,
        },
        data: [
          {
            icon: <PrinterOutlined color='#768499' size={16} />,
            label: 'Phone Number',
            value: props.detailCompany.compPhone,
          },
          {
            icon: <PhoneOutlined color='#768499' size={16} />,
            label: 'PIC Name',
            value: props.detailCompany.compPIC,
          },
          {
            icon: <HomeOutlined color='#768499' size={16} />,
            label: 'Address',
            value: props.detailCompany.compAddress,
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Created At',
            value: convertDate(props.detailCompany.compCreatedTime),
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Updated At',
            value: convertDate(props.detailCompany.compUpdatedTime),
          },
          {
            icon: <UserOutlined color='#768499' size={16} />,
            label: 'Created By',
            value: props.detailCompany.compCreatedUserId,
          },
        ],
      };
    }
  }, [props.detailCompany]);

  const mapDataAsset = useMemo(() => {
    if (props.detailAsset) {
      return {
        title: {
          label: 'Asset Name',
          value: `${props.detailAsset.massName}`,
        },
        data: [
          {
            icon: <UserOutlined color='#768499' size={16} />,
            label: 'PIC Name',
            value: props.detailAsset.massPicName,
          },
          {
            icon: <PhoneOutlined color='#768499' size={16} />,
            label: 'PIC Phone Number',
            value: props.detailAsset.massPicContact,
          },
          {
            icon: <MobileOutlined color='#768499' size={16} />,
            label: 'GSM No.',
            value: props.detailAsset.massGsmNo,
          },
          {
            icon: <CodeOutlined color='#768499' size={16} />,
            label: 'IMEI No.',
            value: props.detailAsset.massImei,
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Installation Date',
            value: convertDate(props.detailAsset.massInstallationDate),
          },
          {
            icon: <WifiOutlined color='#768499' size={16} />,
            label: 'Satellite ID',
            value: props.detailAsset.massSateliteId,
          },
          {
            icon: <WifiOutlined color='#768499' size={16} />,
            label: 'Satellite Operator',
            value: props.detailAsset.massSateliteOperator,
          },
          {
            icon: <InfoCircleOutlined color='#768499' size={16} />,
            label: 'Telco Operator',
            value: props.detailAsset.massTelcoOperator,
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Created At',
            value: convertDate(props.detailAsset.massCreatedTime),
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Updated At',
            value: convertDate(props.detailAsset.massUpdatedTime),
          },
          {
            icon: <UserOutlined color='#768499' size={16} />,
            label: 'Created By',
            value: props.detailAsset.massCreatedUserId,
          },
        ],
      };
    }
  }, [props.detailAsset]);

  const mapDataDevice = useMemo(() => {
    if (props.detailDevice) {
      return {
        title: {
          label: 'Device Name',
          value: `${props.detailDevice.devcName}`,
        },
        data: [
          {
            icon: <InfoCircleOutlined color='#768499' size={16} />,
            label: 'Device Label',
            value: props.detailDevice.devcLabel,
          },
          {
            icon: <InfoCircleOutlined color='#768499' size={16} />,
            label: 'Device Type',
            value: props.detailDevice.devcType,
          },
          {
            icon: <InfoCircleOutlined color='#768499' size={16} />,
            label: 'Device Unique ID',
            value: props.detailDevice.devcUniqueId,
          },
          {
            icon: <AlignLeftOutlined color='#768499' size={16} />,
            label: 'Description',
            value: props.detailDevice.devcDescription,
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Created At',
            value: convertDate(props.detailDevice.devcCreatedTime),
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Updated At',
            value: convertDate(props.detailDevice.devcUpdatedTime),
          },
          {
            icon: <UserOutlined color='#768499' size={16} />,
            label: 'Created By',
            value: props.detailDevice.devcUpdatedUserId,
          },
        ],
      };
    }
  }, [props.detailDevice]);

  const mapDataUser = useMemo(() => {
    if (props.detailUser) {
      return {
        title: {
          label: 'User ID',
          value: `${props.detailUser.userId}`,
        },
        data: [
          {
            icon: <InfoCircleOutlined color='#768499' size={16} />,
            label: 'User Status',
            value:
              props.detailUser.userStatus === '1' ? 'Approved' : 'Not Approved',
          },
          {
            icon: <InfoCircleOutlined color='#768499' size={16} />,
            label: 'Role',
            value: props.detailUser.role,
          },
          {
            icon: <InfoCircleOutlined color='#768499' size={16} />,
            label: 'Total Company',
            value: props.detailUser.totalCompany,
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Created At',
            value: convertDate(props.detailUser.userCreatedTime),
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Updated At',
            value: convertDate(props.detailUser.userUpdatedTime),
          },
          {
            icon: <UserOutlined color='#768499' size={16} />,
            label: 'Created By',
            value: props.detailUser.userUpdatedUserId,
          },
        ],
      };
    }
  }, [props.detailDevice]);

  function _renderHeader() {
    if (props.detailGroup) {
      return (
        <DetailData
          data={mapDataGroupCompany?.data || []}
          title={mapDataGroupCompany?.title || { label: '', value: '' }}
        />
      );
    } else if (props.detailCompany) {
      return (
        <DetailData
          data={mapDataCompany?.data || []}
          title={mapDataCompany?.title || { label: '', value: '' }}
        />
      );
    } else if (props.detailAsset) {
      return (
        <DetailData
          dataAsset={props.detailAsset}
          data={mapDataAsset?.data || []}
          title={mapDataAsset?.title || { label: '', value: '' }}
        />
      );
    } else if (props.detailDevice) {
      return (
        <DetailData
          data={mapDataDevice?.data || []}
          title={mapDataDevice?.title || { label: '', value: '' }}
        />
      );
    } else if (props.detailUser) {
      return (
        <DetailData
          data={mapDataUser?.data || []}
          title={mapDataUser?.title || { label: '', value: '' }}
        />
      );
    } else {
      return false;
    }
  }

  return (
    <Drawer
      title={props.initialTitle}
      width={540}
      open={props.open}
      onClose={() => props.onClose()}
      headerStyle={{ display: 'none' }}
      style={{
        zIndex: 9999,
      }}
    >
      {/* Header Drawer */}
      {_renderHeader()}

      {/* Content Drawer */}
      {isLoading ? (
        <ContainerCenter>
          <Spin />
        </ContainerCenter>
      ) : props.initialState === 'company' ? (
        <DataCompanyList data={listCompanies} />
      ) : props.initialState === 'asset' ? (
        <DataAssetList data={listAssets || []} />
      ) : props.initialState === 'device' ? (
        <DataDeviceList data={listDevice || []} />
      ) : props.initialState === 'none' ? (
        <></>
      ) : (
        <>Not found</>
      )}
    </Drawer>
  );
};

export default DrawerDetail;

const ContainerCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
