import { Slider, Switch, Tag } from 'antd';
import styled from 'styled-components';
import useMapConfig, { EMapTileType } from '../../../hooks/useMapConfig';
import useAsset from '../../../hooks/useAsset';
import useAuth from '../../../hooks/useAuth';
import { AssetType } from '../../../types/asset.type';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

// const options = [
//   { value: '1', label: '24 Hours' },
//   { value: '2', label: '48 Hours' },
//   { value: '3', label: '72 Hours' },
// ];

interface IProps {
  dataMaps?: AssetType[];
}

const MapSettings: React.FC<IProps> = (props) => {
  const {
    showOfflineVessel,
    showOnlineVessel,
    showLabel,
    showTrail,
    trailWidth,
    trailOpacity,
    mapType,
    setShowOfflineVessel,
    setShowOnlineVessel,
    setShowLabel,
    setTrailWidth,
    setTrailOpacity,
    setMapType,
    setShowTrail,
  } = useMapConfig();

  const {
    toggleAssetTrail,
    loadingAllAssetTrail,
    gpsDotTrail,
    handleGPSDotTrail,
  } = useAsset();

  const handleShowOnlineVessel = () => {
    setShowOnlineVessel((oldValue) => !oldValue);
  };

  const handleShowOfflineVessel = () => {
    setShowOfflineVessel((oldValue) => !oldValue);
  };

  const handleShowLabel = () => {
    setShowLabel((oldValue) => !oldValue);
  };

  const handleShowTrail = () => {
    toggleAssetTrail(
      {
        all: !showTrail,
      },
      {
        aggregatedUnit: 'MINUTE',
      }
    );
    setShowTrail((current) => !current);
  };

  const handleChangeTrailWidth = (e: number) => {
    setTrailWidth(e);
  };

  const handleChangeTrailOpacity = (e: number) => {
    setTrailOpacity(e);
  };

  const handleChangeMapType = (e: EMapTileType) => {
    if (e !== mapType) {
      setMapType(e);
    }
  };

  const { handlePermission } = useAuth();

  // if (!props.dataMaps || !handlePermission(['master_company'], 'read')) {
  //   return (
  //     <EmptyCompanyData>
  //       <InfoCircleOutlined style={{ fontSize: 24, marginBottom: 12 }} />
  //       <div>User doesn't have access to read company data</div>
  //     </EmptyCompanyData>
  //   );
  // }

  return (
    <ContainerSettings>
      <SectionWrapper>
        <Title>Vessel Status</Title>
        <FlexBetween>
          <Flex>
            <img alt='icon-setting' src='/images/icon-settings-online.svg' />
            <div>Show Online Vessel</div>
          </Flex>
          <CustomSwitch
            onChange={handleShowOnlineVessel}
            checked={showOnlineVessel}
          />
        </FlexBetween>
        <FlexBetween hideSeparator>
          <Flex>
            <img alt='icon-setting' src='/images/icon-settings-offline.svg' />
            <div>Show Offline Vessel</div>
          </Flex>
          <CustomSwitch
            onChange={handleShowOfflineVessel}
            checked={showOfflineVessel}
          />
        </FlexBetween>
      </SectionWrapper>

      <SectionWrapper>
        <Title>Vessel Element</Title>
        <FlexBetween>
          <Flex>
            <img alt='icon-setting' src='/images/icon-settings-label.svg' />
            <div>Show Vessel Label</div>
          </Flex>
          <CustomSwitch onChange={handleShowLabel} checked={showLabel} />
        </FlexBetween>
        <FlexBetween>
          <Flex>
            <img alt='icon-setting' src='/images/icon-settings-trail.svg' />
            <div>Show Vessel Trail</div>
          </Flex>
          <CustomSwitch
            loading={loadingAllAssetTrail}
            onChange={handleShowTrail}
            checked={showTrail}
          />
        </FlexBetween>
        <FlexBetween hideSeparator>
          <Flex>
            <img alt='icon-setting' src='/images/icon-settings-trail.svg' />
            <div>Show Dot Trail</div>
          </Flex>
          <CustomSwitch
            onChange={handleGPSDotTrail}
            checked={gpsDotTrail === 'active' ? true : false}
          />
        </FlexBetween>
      </SectionWrapper>

      <SectionWrapper>
        <Title>Trail Settings</Title>
        <div style={{ padding: '12px 0' }}>
          <FlexBetween hideSeparator>
            <div>Trail Width</div>
            <Tag color='#768499'>{trailWidth}</Tag>
          </FlexBetween>
          <Slider
            onChange={(e) => handleChangeTrailWidth(e)}
            min={1}
            max={5}
            defaultValue={trailWidth}
          />
        </div>
        <div style={{ padding: '12px 0' }}>
          <FlexBetween hideSeparator>
            <div>Trail Opacity</div>
            <Tag color='#768499'>{trailOpacity}</Tag>
          </FlexBetween>
          <Slider
            onChange={(e) => handleChangeTrailOpacity(e)}
            step={0.1}
            min={0.5}
            max={1.0}
            defaultValue={trailOpacity}
          />
        </div>
        {/* <div style={{ padding: '12px 0' }}>
          <FlexBetween hideSeparator>
            <div>Trail Length</div>
          </FlexBetween>
          <Select
            defaultValue={selectedTrailLength}
            style={{ width: '100%' }}
            onChange={(e) => setSelectedTrailLength(e)}
            options={options}
          />
        </div> */}
      </SectionWrapper>

      <SectionWrapper>
        <Title>Map Type</Title>
        <Flex>
          <MapType
            onClick={() => handleChangeMapType(EMapTileType.satellite)}
            active={mapType === EMapTileType.satellite}
          >
            <img alt='icon-setting' src='/images/map-type-satellite.png' />
            <div>Satellite</div>
          </MapType>
          <MapType
            onClick={() => handleChangeMapType(EMapTileType.vector)}
            active={mapType === EMapTileType.vector}
          >
            <img alt='icon-setting' src='/images/map-type-vector.png' />
            <div>Vector</div>
          </MapType>
        </Flex>
      </SectionWrapper>
    </ContainerSettings>
  );
};

interface ICustomSwitch {
  checked?: boolean;
  onChange: () => void;
  loading?: boolean;
}

export const CustomSwitch: React.FC<ICustomSwitch> = (props) => {
  return (
    <Switch
      style={{ background: props.checked ? '#55C95A' : '#9FACBF' }}
      size='small'
      {...props}
      onChange={props.onChange}
    />
  );
};

const EmptyCompanyData = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;
  opacity: 0.5;
  height: 300px;
  background-color: #d7d7d7;
`;

interface IFlexItem {
  hideSeparator?: boolean;
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;

  img {
    width: 30px;
    height: 30px;
  }
`;

const FlexBetween = styled.div<IFlexItem>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) =>
    !props.hideSeparator
      ? `1px solid ${props.theme.colors.grayLighten_Default}`
      : 'none'};
`;

const SectionWrapper = styled.div`
  margin-bottom: 6px;
  width: 100%;
  padding: 15px 25px;
  background-color: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
`;

const Title = styled.div`
  font-weight: 400;
  color: ${(props) => props.theme.colors.grayDarken_Default};
  margin-bottom: 10px;
`;

const ContainerSettings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grayLighten_Light};
  /* height: 95vh; */
  overflow-y: auto;
`;

interface IMapType {
  active?: boolean;
}

const MapType = styled.div<IMapType>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  gap: 8px;
  div {
    text-align: center;
    color: ${(props) => (props.active ? '#bd1f37' : 'black')};
  }
  img {
    border-radius: 16px;
    width: 64px;
    height: 64px;
    overflow: hidden;
    border: ${(props) =>
      props.active ? '3px solid #bd1f37' : '3px solid #fff'};
    object-fit: cover;
  }
`;

export default MapSettings;
