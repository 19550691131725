import React, { Suspense, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";
import AppLayout from "../screens/layout/AppLayout";
import { Spin } from "antd";
import AuthSvc from "../services/AuthSvc";

const PrivateRoute: React.FC = () => {
  const { isLoggedIn, loginData, logout } = useAuth();

  // if (!isLoggedIn) {
  //   return <Navigate to="/login" replace />;
  // }

  // if (loginData.user === '' || loginData.user === undefined) {
  //   logout();
  //   return <Navigate to="/login" replace />;
  // }

  return (
    <AppLayout>
      <Suspense
        fallback={
          <div className="full-spin">
            <Spin spinning={true} />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </AppLayout>
  );
};
export default PrivateRoute;
