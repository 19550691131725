import { IResponseStatus } from '../helpers/pagination';

export interface IGPSSummary {
  averageSpeed: number;
  calculatedSpeed: number;
  calculatedSpeedKMh: number;
  distance: number;
  end: number;
  interval: number;
  movingTime: number;
  start: number;
  totalDistance: number;
  totalDuration: number;
  totalPolyLength: number;
}

export interface IGPSData {
  track: number;
  satellites: number;
  altitude: number;
  geoidal: number;
  latitude: number;
  longitude: number;
  polyline: string;
  speed: number;
  distance: number;
  id: number;
  deviceId: string;
  timestamp: number;
  datetime: string;
  additional: any;
  polyLength: number;
}

export const initialGPSData: IGPSData = {
  track: 0,
  satellites: 0,
  altitude: 0,
  geoidal: 0,
  latitude: 0,
  longitude: 0,
  polyline: '0',
  speed: 0,
  distance: 0,
  id: 0,
  deviceId: '0',
  timestamp: 0,
  datetime: '0',
  additional: 0,
  polyLength: 0,
};

export interface IRPMSummary {
  singleEngine: boolean;
  interval: number;
  start: number;
  end: number;
  runningTime: {
    MAINENGINE: number;
    STARBOARD: number;
    PORT: number;
    CENTER: number;
  };
  dataCount: {
    STARBOARD: number;
    CENTER: number;
    PORT: number;
  };
  average: {
    PORT: number;
    STARBOARD: number;
    CENTER: number;
    MAINENGINE: number;
  };
  totalRPM: {
    STARBOARD: number;
    PORT: number;
    CENTER: number;
  };
}

export interface IRPMDataSingle {
  type: string;
  no: number;
  rpm: number;
  id: number;
  deviceId: string;
  timestamp: number;
  datetime: string;
  additional: any;
  runningTime: number;
}

export interface IRPMData {
  [T: string]: IRPMDataSingle;
}

export const initialRPMDataSingle: IRPMDataSingle = {
  type: '0',
  no: 0,
  rpm: 0,
  id: 0,
  deviceId: '0',
  timestamp: 0,
  datetime: '0',
  additional: 0,
  runningTime: 0,
};

export const initialPORTRPMData: IRPMData = {
  PORT: {
    type: '0',
    no: 0,
    rpm: 0,
    id: 0,
    deviceId: '0',
    timestamp: 0,
    datetime: '0',
    additional: 0,
    runningTime: 0,
  },
};

export const initialSTBRPMData: IRPMData = {
  STARBOARD: {
    type: '0',
    no: 0,
    rpm: 0,
    id: 0,
    deviceId: '0',
    timestamp: 0,
    datetime: '0',
    additional: 0,
    runningTime: 0,
  },
};

export interface IFlowmeterSummary {
  interval: number;
  start: number;
  end: number;
  BUNKER_IN?: {
    _dataFreq: number;
    _flowTime: number;
    _totalVolumeFlow: number;
    _totalMassFlow: number;
    _averageFlowrate: number;
    _countFlowrate: number;
    _averageMassFlowrate: number;
    _countMassFlowrate: number;
    _averageTemp: number;
    _countTemp: number;
    _averageDens: number;
    _countDens: number;
  };
  PORT_IN: {
    _dataFreq: number;
    _flowTime: number;
    _totalVolumeFlow: number;
    _totalMassFlow: number;
    _averageFlowrate: number;
    _countFlowrate: number;
    _averageMassFlowrate: number;
    _countMassFlowrate: number;
    _averageTemp: number;
    _countTemp: number;
    _averageDens: number;
    _countDens: number;
  };
  PORT_OUT: {
    _dataFreq: number;
    _flowTime: number;
    _totalVolumeFlow: number;
    _totalMassFlow: number;
    _averageFlowrate: number;
    _countFlowrate: number;
    _averageMassFlowrate: number;
    _countMassFlowrate: number;
    _averageTemp: number;
    _countTemp: number;
    _averageDens: number;
    _countDens: number;
  };
  STARBOARD_IN: {
    _dataFreq: number;
    _flowTime: number;
    _totalVolumeFlow: number;
    _totalMassFlow: number;
    _averageFlowrate: number;
    _countFlowrate: number;
    _averageMassFlowrate: number;
    _countMassFlowrate: number;
    _averageTemp: number;
    _countTemp: number;
    _averageDens: number;
    _countDens: number;
  };
  STARBOARD_OUT: {
    _dataFreq: number;
    _flowTime: number;
    _totalVolumeFlow: number;
    _totalMassFlow: number;
    _averageFlowrate: number;
    _countFlowrate: number;
    _averageMassFlowrate: number;
    _countMassFlowrate: number;
    _averageTemp: number;
    _countTemp: number;
    _averageDens: number;
    _countDens: number;
  };
  AE_IN?: {
    _dataFreq: number;
    _flowTime: number;
    _totalVolumeFlow: number;
    _totalMassFlow: number;
    _averageFlowrate: number;
    _countFlowrate: number;
    _averageMassFlowrate: number;
    _countMassFlowrate: number;
    _averageTemp: number;
    _countTemp: number;
    _averageDens: number;
    _countDens: number;
  };
  AE_OUT?: {
    _dataFreq: number;
    _flowTime: number;
    _totalVolumeFlow: number;
    _totalMassFlow: number;
    _averageFlowrate: number;
    _countFlowrate: number;
    _averageMassFlowrate: number;
    _countMassFlowrate: number;
    _averageTemp: number;
    _countTemp: number;
    _averageDens: number;
    _countDens: number;
  };
  portEngineCons: number;
  portAverageFlow: number;
  starboardAverageFlow: number;
  starboardEngineCons: number;
  aeAverageFlow: number;
  aeEngineCons: number;
  bunkerEngineCons: number;
  bunkerAverageFlow: number;
  meEngineCons: number;
}

export interface IFlowmeterSingle {
  massFlowrate: number;
  density: number;
  temperature: number;
  volumeFlowrate: number;
  massTotal: number;
  volumeTotal: number;
  massInventory: number;
  volumeInventory: number;
  id: number;
  deviceId: string;
  timestamp: number;
  datetime: string;
  additional: {
    FIRST_dlfmVolInventory_0: number;
    LAST_dlfmVolInventory_1: number;
    AVG_dlfmVolFlowrate_2: number;
    AVG_dlfmDensity_3: number;
    SUMGT_dlfmDensity_4: number;
    COUNTGT_dlfmDensity_5: number;
    AVG_dlfmTemp_6: number;
    SUMGT_dlfmTemp_7: number;
    COUNTGT_dlfmTemp_8: number;
    SUMGT_dlfmMassFlowrate_9: number;
    COUNTGT_dlfmMassFlowrate_10: number;
    SUMGT_dlfmVolFlowrate_11: number;
    COUNTGT_dlfmVolFlowrate_12: number;
    FIRST_dlfmMassTotal_13: number;
    LAST_dlfmMassTotal_14: number;
  };
  volumeFlow: number;
}

export const initialFlowmeterSingle: IFlowmeterSingle = {
  massFlowrate: 0,
  density: 0,
  temperature: 0,
  volumeFlowrate: 0,
  massTotal: 0,
  volumeTotal: 0,
  massInventory: 0,
  volumeInventory: 0,
  id: 0,
  deviceId: '',
  timestamp: 0,
  datetime: '',
  additional: {
    FIRST_dlfmVolInventory_0: 0,
    LAST_dlfmVolInventory_1: 0,
    AVG_dlfmVolFlowrate_2: 0,
    AVG_dlfmDensity_3: 0,
    SUMGT_dlfmDensity_4: 0,
    COUNTGT_dlfmDensity_5: 0,
    AVG_dlfmTemp_6: 0,
    SUMGT_dlfmTemp_7: 0,
    COUNTGT_dlfmTemp_8: 0,
    SUMGT_dlfmMassFlowrate_9: 0,
    COUNTGT_dlfmMassFlowrate_10: 0,
    SUMGT_dlfmVolFlowrate_11: 0,
    COUNTGT_dlfmVolFlowrate_12: 0,
    FIRST_dlfmMassTotal_13: 0,
    LAST_dlfmMassTotal_14: 0,
  },
  volumeFlow: 0,
};

export interface IFlowmeterData {
  AE_FuelConsVolume: number;
  ME_FuelCons: number;
  PORT_IN: IFlowmeterSingle;
  PORT_OUT: IFlowmeterSingle;
  STARBOARD_IN: IFlowmeterSingle;
  STARBOARD_OUT: IFlowmeterSingle;
  BUNKER_IN?: IFlowmeterSingle;
  portFuelCons: number;
  portInFlow: number;
  portInDens: number;
  portOutFlow: number;
  portOutDens: number;
  portFlow: number;
  stbFuelCons: number;
  stbInFlow: number;
  stbInDens: number;
  stbOutFlow: number;
  stbOutDens: number;
  stbFlow: number;
  meFuelCons: number;
  aeFuelCons: number;
  AE_FuelCons: number;
  PORT_Flow: number;
  PORT_FuelCons: number;
  PORT_FuelConsVolume: number;
  PORT_InDens: number;
  PORT_InFlow: number;
  PORT_OutDens: number;
  PORT_OutFlow: number;
  STARBOARD_Flow: number;
  STARBOARD_FuelCons: number;
  STARBOARD_FuelConsVolume: number;
  STARBOARD_InDens: number;
  STARBOARD_InFlow: number;
  STARBOARD_OutDens: number;
  STARBOARD_OutFlow: number;
}

export const initialFlowmeterData: IFlowmeterData = {
  PORT_IN: initialFlowmeterSingle,
  PORT_OUT: initialFlowmeterSingle,
  STARBOARD_IN: initialFlowmeterSingle,
  STARBOARD_OUT: initialFlowmeterSingle,
  portFuelCons: 0,
  portInFlow: 0,
  portInDens: 0,
  portOutFlow: 0,
  portOutDens: 0,
  portFlow: 0,
  stbFuelCons: 0,
  stbInFlow: 0,
  stbInDens: 0,
  stbOutFlow: 0,
  stbOutDens: 0,
  stbFlow: 0,
  meFuelCons: 0,
  aeFuelCons: 0,
  AE_FuelCons: 0,
  PORT_Flow: 0,
  PORT_FuelCons: 0,
  PORT_FuelConsVolume: 0,
  PORT_InDens: 0,
  PORT_InFlow: 0,
  PORT_OutDens: 0,
  PORT_OutFlow: 0,
  STARBOARD_Flow: 0,
  STARBOARD_FuelCons: 0,
  STARBOARD_FuelConsVolume: 0,
  STARBOARD_InDens: 0,
  STARBOARD_InFlow: 0,
  STARBOARD_OutDens: 0,
  STARBOARD_OutFlow: 0,
  ME_FuelCons: 0,
  AE_FuelConsVolume:0,
};

export interface IAESummary {
  interval: number;
  start: number;
  end: number;
  runningTime: {
    AE1: number;
    AE2: number;
    AE3: number;
  };
  runningSeconds: {
    AE1: number;
    AE2: number;
    AE3: number;
    AE4: number;
  };
  fuelConsumption: {
    AE1: number;
    AE2: number;
    AE3: number;
    AE4: number;
  };
}

export interface IAEDataSingle {
  no: number;
  rpm: number;
  id: number;
  deviceId: string;
  timestamp: number;
  datetime: string;
  additional: {
    AVG_dlaeRpm_0: number;
    SUM_dlaeRpm_1: number;
  };
  runningTime: number;
  runningSeconds: number;
  fuelConsumption: number;
}

export const InitialAEDataSingle: IAEDataSingle = {
  no: 0,
  rpm: 0,
  id: 0,
  deviceId: '',
  timestamp: 0,
  datetime: '',
  additional: {
    AVG_dlaeRpm_0: 0,
    SUM_dlaeRpm_1: 0,
  },
  runningTime: 0,
  runningSeconds: 0,
  fuelConsumption: 0,
};

export interface IAEData {
  AE1: IAEDataSingle;
  AE2: IAEDataSingle;
  AE3: IAEDataSingle;
  AE4: IAEDataSingle;
}

export const initialAEData: IAEData = {
  AE1: InitialAEDataSingle,
  AE2: InitialAEDataSingle,
  AE3: InitialAEDataSingle,
  AE4: InitialAEDataSingle,
};

export interface SummaryHttpResponse {
  data: {
    gps: {
      summary: IGPSSummary;
      data: {
        [T: string]: IGPSData;
      };
    };
    rpm: {
      summary: IRPMSummary;
      data: {
        [T: string]: IRPMData;
      };
    };
    flowmeter: {
      summary: IFlowmeterSummary;
      data: {
        [T: string]: IFlowmeterData;
      };
    };
    ae: {
      summary: IAESummary;
      data: {
        [T: string]: IAEData;
      };
    };
  };
  status: IResponseStatus;
}

export interface ISummaryOld {
  gps: {
    summary: IGPSSummary;
    data: IGPSData[];
    timestamps: number[];
  };
  rpm: {
    summary: IRPMSummary;
    data: IRPMData[];
    timestamps: number[];
  };
  flowmeter?: {
    summary: IFlowmeterSummary;
    data: IFlowmeterData[];
    timestamps: number[];
  };
  ae: {
    summary: IAESummary;
    data: IAEData[];
    timestamps: number[];
  };
}

export interface ISummary {
  gps?: {
    summary: IGPSSummary;
    data: IGPSData[];
    timestamps: number[];
  };
  rpm?: {
    summary: IRPMSummary;
    data: IRPMData[];
    timestamps: number[];
  };
  flowmeter?: {
    summary: IFlowmeterSummary;
    data: IFlowmeterData[];
    timestamps: number[];
  };
  ae?: {
    summary: IAESummary;
    data: IAEData[];
    timestamps: number[];
  };
}

export interface ISummarySingleDevice {
  [T: string]: {
    summary?: Partial<
      IGPSSummary & IRPMSummary & IFlowmeterSummary & IAESummary
    >;
    data?: IGPSData[] | IRPMData[] | IFlowmeterData[] | IAEData[];
  };
}
