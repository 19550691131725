import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import StatusTag from "../../../../components/StatusTag";

interface IProps {
  data: any
}
export const EventEngine: React.FC<IProps> = (props) => {

  return <React.Fragment>
    <table
      className={
        'custom-table-daily-report-summary'
      }
      id='summaryTable'
    >
      <thead>
        <tr>
          <th>Start</th>
          <th>End</th>
          <th>Engine</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {props.data && props.data.map((elm: any, index: number) => (
          <tr key={index}>
            <td>{moment(elm.start * 1000).format('YYYY-MM-DD HH:mm:ss')}</td>
            <td>{moment(elm.end * 1000).format('YYYY-MM-DD HH:mm:ss')}</td>
            <td>{elm.engine.toUpperCase()}</td>
            <td>{elm.status === 'on' ? (
          <StatusTag isOnline={true} customText='ON' />
        ) : (
          <StatusTag isOnline={false} customText='OFF' />
        )}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </React.Fragment>;
}