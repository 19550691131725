import React from 'react';
import RootNavigator from './navigation/RootNavigator';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './assets/app.less';
import AuthProvider from './context/AuthProvider';
import ConfigProvider from './context/ConfigProvider';
import { Alert } from 'antd';
import { ThemeProvider } from 'styled-components';
import { theme } from './assets/theme';

const { ErrorBoundary } = Alert;
function App() {
  return (
    <ErrorBoundary>
      <ConfigProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <RootNavigator />
          </AuthProvider>
        </ThemeProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
