import { Button, Form, Input, Modal, message } from 'antd';
import {
  IReportTargetHeader,
  IReportTargetHeaderRequest,
} from '../../../types/masterReport.type';
import useAuth from '../../../hooks/useAuth';
import { useEffect, useMemo, useState } from 'react';
import { MasterTargetHeaderSvc } from '../../../services/MasterReportSvc';

interface Props {
  isOpen: boolean;
  data?: IReportTargetHeader;
  afterSubmit: () => void;
  compId: string;
}

const ModalMasterEmailReceiver: React.FC<Props> = ({
  isOpen,
  data,
  afterSubmit,
  compId,
}) => {
  const { handlePermission } = useAuth();
  const [form] = Form.useForm<IReportTargetHeaderRequest>();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(false);

  async function create() {
    try {
      setIsLoadingSubmit(true);
      const values = await form.validateFields();
      const formData = {
        mrthEmail: values.mrthEmail,
        mrthCompId: compId,
      };
      await MasterTargetHeaderSvc.addTargetHeader(formData);
      message.success('Success add data ' + values.mrthEmail);
      handleReset();
    } catch (error: any) {
      if (error.errorFields && error.errorFields.length > 0) {
        message.error('Error Validation: Check your input');
      } else {
        message.error('Error: ' + error);
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  async function update() {
    try {
      setIsLoadingSubmit(true);
      const values = await form.validateFields();
      const formData = {
        mrthId: data?.mrthId,
        mrthEmail: values.mrthEmail,
        mrthCompId: compId,
      };
      await MasterTargetHeaderSvc.updateTargetHeader(formData);
      message.success('Success edit data ' + values.mrthEmail);
      handleReset();
    } catch (error: any) {
      if (error.errorFields && error.errorFields.length > 0) {
        message.error('Error Validation: Check your input');
      } else {
        message.error('Error: ' + error);
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  async function handleOnSubmit() {
    if (data?.mrthId) {
      await update();
    } else {
      await create();
    }
  }

  function handleReset() {
    form.resetFields();
    afterSubmit();
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };

  return (
    <Modal
      width={'45%'}
      title={data && data.mrthId ? 'Edit Email Receiver' : 'Add Email Receiver'}
      open={isOpen}
      onCancel={handleReset}
      className='custom-modal'
      footer={[
        <Button
          style={{ background: '#FFFFFF', color: '#000000' }}
          onClick={handleReset}
        >
          Cancel
        </Button>,
        <Button
          loading={isLoadingSubmit}
          disabled={
            (!handlePermission(['master_report_target_header'], 'create') &&
              !handlePermission(['master_report_target_header'], 'update')) ||
            disabledSave
          }
          onClick={handleOnSubmit}
          type='primary'
        >
          {data && data.mrthId ? 'Update' : 'Create'}
        </Button>,
      ]}
    >
      <Form layout='vertical' form={form} onFieldsChange={handleFormChange}>
        <Form.Item
          label='Email'
          name='mrthEmail'
          rules={[
            {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: `Email format is invalid.`,
            },
          ]}
        >
          <Input placeholder='Enter email' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalMasterEmailReceiver;
