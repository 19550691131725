import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: '3px',
  fontSize: {
    heading1: '60px',
    heading2: '48px',
    heading3: '39px',
    heading4: '31px',
    title1: '25px',
    title2: '20px',
    body1: '16px',
    body2: '14px',
    caption: '12px',
  },
  fontWeight: {
    regular: '400',
    semiBold: '600',
  },

  colors: {
    // Base Color
    black: '#1E1E1E',
    white: '#FFFFFF',

    // Main color
    primary: '#bd1f37',
    secondary: '#37CBDF',
    accent: '#E5398C',

    // Gray Lighten
    grayLighten_Light: '#F4F6F9',
    grayLighten_Default: '#E8ECF3',
    grayLighten_Dark: '#C5CDDB',

    // Gray Darken
    grayDarken_Light: '#9FACBF',
    grayDarken_Default: '#768499',
    grayDarken_Dark: '#3F4C5F',

    // System Color
    // Success
    success_Light: '#DFF4E0',
    success_Default: '#55C95A',
    success_Dark: '#138918',

    // Info
    info_Light: '#E7F3FC',
    info_Default: '#20A1F5',
    info_Dark: '#0B5585',

    // Warning
    warning_Light: '#FFF8E4',
    warning_Default: '#FFBC00',
    warning_Dark: '#A4660A',

    // Danger
    danger_Light: '#FDEBEB',
    danger_Default: '#FE4545',
    danger_Dark: '#970B0B',
  },
};

export { theme };
