import { IAssetData } from './asset.type';
import { CompanyProps } from './company.type';
import { DeviceProps } from './device.type';

export interface IOperationHeader {
  mrohCompId: string;
  mrohComp: CompanyProps;
  mrohCreatedTime: string;
  mrohCreatedUserId: string;
  mrohDevcId: string;
  mrohDevc: DeviceProps;
  mrohId: string;
  mrohMassId: string;
  mrohMass: IAssetData;
  mrohStartTime: string;
  mrohStatus: string;
  mrohUpdatedTime: string;
  mrohUpdatedUserId: string;
}

export interface IOperationDetail {
  mrodId: string;
  mrodMrohId: string;
  mrodMroh?: IOperationHeader;
  mrodName: string;
  mrodRpmMax: string;
  mrodConsRate: string;
  mrodCreatedTime: string;
  mrodCreatedUserId: string;
  mrodUpdatedTime: string;
  mrodUpdatedUserId: string;
}

export const initOperationTemplate = [
  {
    "mrodId": "",
    "mrodMrohId": "",
    "mrodName": "Off",
    "mrodRpmMax": "",
    "mrodConsRate": "",
    "mrodCreatedUserId": "",
    "mrodCreatedTime": "",
    "mrodUpdatedUserId": "",
    "mrodUpdatedTime": ""
  },
  {
    "mrodId": "",
    "mrodMrohId": "",
    "mrodName": "Idle",
    "mrodRpmMax": "",
    "mrodConsRate": "",
    "mrodCreatedUserId": "",
    "mrodCreatedTime": "",
    "mrodUpdatedUserId": "",
    "mrodUpdatedTime": ""
  },
  {
    "mrodId": "",
    "mrodMrohId": "",
    "mrodName": "Slow",
    "mrodRpmMax": "",
    "mrodConsRate": "",
    "mrodCreatedUserId": "",
    "mrodCreatedTime": "",
    "mrodUpdatedUserId": "",
    "mrodUpdatedTime": ""
  },
  {
    "mrodId": "",
    "mrodMrohId": "",
    "mrodName": "Eco",
    "mrodRpmMax": "",
    "mrodConsRate": "",
    "mrodCreatedUserId": "",
    "mrodCreatedTime": "",
    "mrodUpdatedUserId": "",
    "mrodUpdatedTime": ""
  },
  {
    "mrodId": "",
    "mrodMrohId": "",
    "mrodName": "Full",
    "mrodRpmMax": "",
    "mrodConsRate": "",
    "mrodCreatedUserId": "",
    "mrodCreatedTime": "",
    "mrodUpdatedUserId": "",
    "mrodUpdatedTime": ""
  }
]