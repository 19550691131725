import React from "react";
import moment from "moment";
import styled from "styled-components";
import { Spin } from "antd";

import {
  latitudeFormat,
  longitudeFormat,
} from "../../../helpers/map-util";
import { toKM, toKnot } from "../../../helpers/dateUtil";

import { LoadingWrapper } from "../../report";

import { AssetType } from "../../../types/asset.type";

interface CoordinatePopupProps {
  speed: number;
  longitude: number;
  latitude: number;
  altitude: number;
  heading: number;
  timestamp: number;
  distance: number;
  asset: AssetType;
}

const CoordinatePopup: React.FC<CoordinatePopupProps> = ({
  speed,
  longitude,
  latitude,
  altitude,
  timestamp,
  distance,
  asset,
}) => {
  const convertTimestampToDate = (timestamp?: number) => {
    if (timestamp) {
      return moment(timestamp * 1000).format("DD MMM YYYY HH:mm");
    }

    return "-";
  };
  return (
    <Container className="custom-popup">
      {false ? (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      ) : (
        <>
          <TitleWrapper>
            <Title>{asset.massName}</Title>
          </TitleWrapper>
          <ContentWrapper>
            <GridContent>
              <ItemContent
                label="Time"
                value={convertTimestampToDate(timestamp)}
              />
              <ItemContent
                label="Latitude"
                value={latitudeFormat(latitude, longitude)}
              />
              <ItemContent
                label="Longitude"
                value={longitudeFormat(latitude, longitude)}
              />
              <ItemContent label="Altitude" value={altitude.toFixed(2)} />
              <ItemContent label="Speed" value={toKnot(speed) + " Knot"} />
              <ItemContent label="Distance" value={toKM(distance) + " KM"} />
            </GridContent>
          </ContentWrapper>
        </>
      )}
    </Container>
  );
};

interface IItemProps {
  label: string;
  value: string;
}

const ItemContent: React.FC<IItemProps> = ({ label, value }) => {
  return (
    <Item>
      <div>{label}</div>
      <p>{value}</p>
    </Item>
  );
};

const Container = styled.div`
  border-radius: 4px;
  width: 320px;
  overflow: hidden;
  img {
    width: 100%;
    height: 172px;
    object-fit: cover;
  }
`;

const TitleWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayLighten_Light};
  padding: 12px 20px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ContentWrapper = styled.div`
  padding: 10px;
`;

const GridContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Item = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  div {
    color: ${(props) => props.theme.colors.grayDarken_Default};
    margin-bottom: 5px;
  }
  p {
    color: ${(props) => props.theme.colors.black};
    margin: 0;
    font-size: 12px;
  }
`;

export default CoordinatePopup;
