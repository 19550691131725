import { useContext, useState } from 'react';
import { removeToken } from '../helpers/auth';
import { EAuthStatus } from '../types/auth.type';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import AuthContext from '../context/AuthContext';
import AuthSvc from '../services/AuthSvc';

export default function useAuth() {
  const { auth, setAuth } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const login = async ({ data }: { data: object }) => {
    try {
      const loginData = async () => {
        const result = await AuthSvc.getUserStatus();
        return result.data;
      };

      setAuth({
        ...data,
        status: EAuthStatus.LOGGED_IN,
        loginData: await loginData(),
        roles: [],
        access: (await loginData()).access,
      });

      setIsLoading(false);
    } catch (err) {
      message.error('Login failed. ' + getErrorMessage(err));
      setIsLoading(false);
    }
  };

  const logout = async () => {
    await AuthSvc.LogOut();
    removeToken();
    setAuth({
      status: EAuthStatus.LOGGED_OUT,
      loginData: [],
    });
  };

  const handlePermission = (module: string[], access?: any) => {
    let allowed = false;
    if (!auth || !auth.access) {
      allowed = false;
    }

    if(auth.access){
      const found = Object.keys(auth.access).find((key) => {
        if (access) {
          const currAccess = auth.access[key];
          return module.includes(key) && currAccess.includes(access);
        } else {
          return module.includes(key);
        }
      });
      found && (allowed = true);
    }


    return allowed;
  };

  return {
    isLoading,
    isLoggedIn: auth.status === EAuthStatus.LOGGED_IN,
    user: auth.user,
    profile: auth.profile,
    loginData: auth.loginData,
    access: auth.access,
    login,
    logout,
    handlePermission,
  };
}
