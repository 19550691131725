import { Button, Form, Input, Modal, message } from 'antd';
import {
  IReportTemplate,
  IReportTemplateRequest,
} from '../../../types/masterReport.type';
import useAuth from '../../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { MasterReportTemplateSvc } from '../../../services/MasterReportSvc';

interface Props {
  isOpen: boolean;
  data?: IReportTemplate;
  afterSubmit: () => void;
  compId: string;
}

const ModalMasterReportTemplate: React.FC<Props> = ({
  isOpen,
  data,
  afterSubmit,
  compId,
}) => {
  const { handlePermission } = useAuth();
  const [form] = Form.useForm<IReportTemplateRequest>();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(false);

  async function create() {
    try {
      setIsLoadingSubmit(true);
      const values = await form.validateFields();
      const formData = {
        mrtpName: values.mrtpName,
        mrtpSubject: values.mrtpSubject,
        mrtpBody: values.mrtpBody.replace(/[\n\r]+/g, ''),
        mrtpCompId: compId,
      };
      await MasterReportTemplateSvc.addMasterReport(formData);
      message.success('Success add data ' + values.mrtpName);
      handleReset();
    } catch (error: any) {
      if (error.errorFields && error.errorFields.length > 0) {
        message.error('Error Validation: Check your input');
      } else {
        message.error('Error: ' + error);
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  async function update() {
    try {
      setIsLoadingSubmit(true);
      const values = await form.validateFields();
      const formData = {
        mrtpId: data?.mrtpId,
        mrtpName: values.mrtpName,
        mrtpSubject: values.mrtpSubject,
        mrtpBody: values.mrtpBody.replace(/[\n\r]+/g, ''),
        mrtpCompId: compId,
      };
      await MasterReportTemplateSvc.updateMasterReport(formData);
      message.success('Success edit data ' + values.mrtpName);
      handleReset();
    } catch (error: any) {
      if (error.errorFields && error.errorFields.length > 0) {
        message.error('Error Validation: Check your input');
      } else {
        message.error('Error: ' + error);
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  async function handleOnSubmit() {
    if (data?.mrtpId) {
      await update();
    } else {
      await create();
    }
  }

  function handleReset() {
    form.resetFields();
    afterSubmit();
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };

  return (
    <Modal
      width={'45%'}
      title={
        data && data.mrtpId ? 'Edit Master Template' : 'Add Master Template'
      }
      open={isOpen}
      onCancel={handleReset}
      className='custom-modal'
      footer={[
        <Button
          style={{ background: '#FFFFFF', color: '#000000' }}
          onClick={handleReset}
        >
          Cancel
        </Button>,
        <Button
          loading={isLoadingSubmit}
          disabled={
            (!handlePermission(['master_report_template'], 'create') &&
              !handlePermission(['master_report_template'], 'update')) ||
            disabledSave
          }
          onClick={handleOnSubmit}
          type='primary'
        >
          {data && data.mrtpId ? 'Update' : 'Create'}
        </Button>,
      ]}
    >
      <Form layout='vertical' onFieldsChange={handleFormChange} form={form}>
        <Form.Item
          label='Template Name'
          name='mrtpName'
          rules={[{ required: true, message: 'Please input template name!' }]}
        >
          <Input placeholder='Enter template name' />
        </Form.Item>

        <Form.Item
          label='Email Subject'
          name='mrtpSubject'
          rules={[{ required: true, message: 'Please input subject!' }]}
        >
          <Input.TextArea placeholder='Enter email subject' rows={5} />
        </Form.Item>

        <Form.Item
          label='Email Body'
          name='mrtpBody'
          rules={[{ required: true, message: 'Please input body!' }]}
        >
          <Input.TextArea placeholder='Enter email body' rows={12} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalMasterReportTemplate;
