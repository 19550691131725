import styled from 'styled-components';
import ModalChangeCompany from '../../components/ModalChangeCompany';
import { useEffect, useMemo, useRef, useState } from 'react';
import useCompany from '../../hooks/useCompany';
import { Skeleton, Spin } from 'antd';
import MapSummaryCompanyInfo from '../home/components/MapSummaryCompanyInfo';
import useAsset from '../../hooks/useAsset';
import useConfigApp from '../../hooks/useConfigApp';
import { AssetType } from '../../types/asset.type';
import buildFilters from '../../helpers/requestBuilder';
import DeviceSvc from '../../services/DeviceSvc';
import useDevice from '../../hooks/useDevice';
import AuthSvc from '../../services/AuthSvc';
import CompanySvc from '../../services/CompanySvc';
import AssetSvc from '../../services/AssetSvc';
import FilterDashboard from '../dashboard/components/FilterDashboard';
import { ChevronRight, Info } from 'react-feather';
import { useSearchParams } from 'react-router-dom';
import useFilter from '../../hooks/useFilter';
import HChartBunker from '../../components/charts/HChartBunker';
import { intervalOptions } from '../../helpers/map';
import { generateHourArray } from '../../helpers/dateUtil';

interface IDeviceSummaries {
  devcName: string;
  massFlowRate: number;
  density: number;
  volumeTotal: number;
}

const HomeBMS: React.FC = () => {
  const [openModalCompany, setOpenModalCompany] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(true);
  const [sidebarExpand, setSidebarExpand] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dataFetchedRef = useRef(false);
  const dataFetchedDeviceRef = useRef(false);
  const dataFetchedCompRef = useRef(false);

  const handleToggleSummary = () => {
    setShowSummary(!showSummary);
  };

  const {
    activeCompany,
    fetchCompanyData,
    isLoadingCompanyData,
    activeCompanyData,
  } = useCompany();

  const { isLoadingAsset, assets2, fetchAssets, fetchAssetsWithLastData } =
    useAsset();
  const { isLoadingFilter, dataFilter, setDataFilter } = useFilter();
  const { sidebarCollapsed } = useConfigApp();
  const refChart: any = useRef();
  const [deviceSummaries, setDeviceSummaries] = useState<IDeviceSummaries[]>(
    []
  );

  const currAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter, assets2]);

  const handleChangeCompany = () => {
    setOpenModalCompany(true);
  };

  const handleConfirmCompany = async () => {
    setOpenModalCompany(false);
    // await handleSetActiveAsset();
  };

  const getDevices = async () => {
    try {
      const resDevices = await DeviceSvc.getDevices({
        params: {
          page: 1,
          max: 1000,
          devcMassId: currAsset?.massId,
          devcType: ['flowmeter'],
        },
      });

      if (resDevices && resDevices.data.data) {
        const currDevices = resDevices.data.data.filter(
          (item) =>
            item.devcLabel.toUpperCase().includes('BUNKER_IN') &&
            item.devcMassId === currAsset?.massId
        );

        const summaryFlowmeter =
          currAsset &&
          currAsset.dataSummary &&
          currAsset.dataSummary.flowmeter &&
          currAsset.dataSummary.flowmeter.summary &&
          currAsset.dataSummary.flowmeter.summary.BUNKER_IN
            ? currAsset.dataSummary.flowmeter.summary.BUNKER_IN
            : undefined;
        console.log('summaryFlowmeter', currAsset?.dataSummary);
        setDeviceSummaries(
          currDevices.map((item) => {
            if (item.massId === currAsset?.massId) {
              return {
                devcName: item.devcName,
                massFlowRate: summaryFlowmeter
                  ? summaryFlowmeter._averageMassFlowrate
                  : 0,
                density: summaryFlowmeter ? summaryFlowmeter._averageDens : 0,
                volumeTotal: summaryFlowmeter ? summaryFlowmeter._totalVolumeFlow : 0,
              };
            } else {
              return {
                devcName: item.devcName,
                massFlowRate: 0,
                density: 0,
                volumeTotal: 0,
              };
            }
          })
        );
      }
    } catch (error: any) {
      console.error('error getDevices: ', error);
    }
  };

  useEffect(() => {
    if (!sidebarCollapsed) {
      setSidebarExpand(true);
    } else {
      setSidebarExpand(false);
    }
  }, [sidebarCollapsed]);

  useEffect(() => {
    if (currAsset && currAsset.massId && currAsset.dataSummary) {
      if (dataFetchedDeviceRef.current) return;
      dataFetchedDeviceRef.current = true;

      getDevices();
    }
  }, [currAsset]);

  const handleSetURLParams = (data: any) => {
    console.log('handleSetURLParams', data);
    setSearchParams(data);
  };

  const columns = useMemo(() => {
    return generateHourArray(dataFilter.range.startAt, dataFilter.range.endAt);
  }, [dataFilter.range]);

  useEffect(() => {
    if (dataFetchedCompRef.current) return;
    dataFetchedCompRef.current = true;

    const searchParams = new URLSearchParams(document.location.search);
    const queryMassId = searchParams.get('massId');
    const queryStart = searchParams.get('start');
    const queryEnd = searchParams.get('end');
    const queryAggregatedUnit = searchParams.get('aggregatedUnit');

    fetchAssetsWithLastData({
      aggregatedUnit: queryAggregatedUnit
        ? queryAggregatedUnit
        : intervalOptions[0].value,
      start: queryStart ? queryStart : undefined,
      end: queryEnd ? queryEnd : undefined,
      devcMassId: queryMassId
        ? queryMassId
        : dataFilter.massId
        ? dataFilter.massId
        : 'initial'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  return (
    <>
      {/* Summary & Map Settings */}
      <ToggleSummary
        isSidebarExpand={sidebarExpand}
        title={showSummary ? 'Hide Summary' : 'Show Summary'}
        showSummary={showSummary}
        onClick={() => handleToggleSummary()}
      >
        <img
          style={{width:24, margin:"50px 0 0 0" }}
          alt='toggle-summary'
          src={
            showSummary
              ? '/images/toggle-hide-summary.svg'
              : '/images/toggle-show-summary.svg'
          }
        />
      </ToggleSummary>

      {showSummary && (
        <MapSummary isSidebarExpand={sidebarExpand}>
          {isLoadingCompanyData ? (
            <Skeleton active />
          ) : (
            <MapSummaryCompanyInfo
              companyId={activeCompanyData ? activeCompanyData.compId : '-'}
              companyName={activeCompanyData ? activeCompanyData.compName : '-'}
              companyContact={activeCompanyData.compPhone}
              companyPIC={activeCompanyData.compPIC}
              companyPICPhone={activeCompanyData.compPICPhone}
              companyGroupName={
                activeCompanyData ? activeCompanyData.compGrcp.grcpName : '-'
              }
              changeCompany={handleChangeCompany}
              isLoading={isLoadingCompanyData || isLoadingAsset}
            />
          )}
          {deviceSummaries.length > 0 ? (
            deviceSummaries.map((item, idx) => (
              <AssetItem key={idx}>
                <DeviceInfoWrapper>
                  <TitleDevice>{item.devcName}</TitleDevice>
                  <ChevronRight color={'#9FACBF'} size={16} />
                </DeviceInfoWrapper>
                <DeviceWrapper>
                  <DataDevice>
                    <span>Mass Flow Rate</span>
                    <Flex>
                      <div>{item?.massFlowRate && item?.massFlowRate.toFixed(2)}</div>
                      <span>MT/HR</span>
                    </Flex>
                  </DataDevice>
                  <DataDevice>
                    <span>Density</span>
                    <Flex>
                      <div>{item?.density && item?.density.toFixed(2)}</div>
                      <span>
                        g/cm<sup>3</sup>
                      </span>
                    </Flex>
                  </DataDevice>
                  <DataDevice>
                    <span>Volume Total</span>
                    <Flex>
                      <div>{item?.volumeTotal && item?.volumeTotal.toFixed(2)}</div>
                      <span>
                        m<sup>3</sup>
                      </span>
                    </Flex>
                  </DataDevice>
                </DeviceWrapper>
              </AssetItem>
            ))
          ) : (
            <AssetItem>
              <div>Data bunkering not found</div>
            </AssetItem>
          )}
        </MapSummary>
      )}

      {/* Chart Component */}
      {isLoadingCompanyData || isLoadingAsset ? (
        <ContainerLoading
          isShowSummary={showSummary}
          isSidebarExpand={sidebarExpand}
        >
          <LoadingWrapper>
            <Spin />
            <div>Loading data</div>
          </LoadingWrapper>
        </ContainerLoading>
      ) : (
        <ContainerContentBMS
          isShowSummary={showSummary}
          isSidebarExpand={sidebarExpand}
        >
          <FilterDashboard
            setURLParams={handleSetURLParams}
            searchParams={searchParams}
          />
          {!currAsset ? (
            <ContentWrapper
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 142px)',
              }}
            >
              <LoadingWrapper>
                <Info color='gray' />
                <div>Data bunkering not found</div>
              </LoadingWrapper>
            </ContentWrapper>
          ) : isLoadingFilter ? (
            <ContentWrapper
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 142px)',
              }}
            >
              <LoadingWrapper>
                <Spin />
                <div>Loading data</div>
              </LoadingWrapper>
            </ContentWrapper>
          ) : (
            <ContentWrapper>
              <DataWrapper>
                <HChartBunker
                  ref={refChart}
                  isDailyReport
                  isBMSHome
                  data={currAsset.dataSummary || undefined}
                  columns={columns}
                />
              </DataWrapper>
            </ContentWrapper>
          )}
        </ContainerContentBMS>
      )}

      <ModalChangeCompany
        open={openModalCompany}
        onCloseModal={handleConfirmCompany}
        onCloseModalSubmit={() => {
          handleConfirmCompany();
        }}
      />
    </>
  );
};

const DataWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  padding: 5px;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
  height: calc(100vh - 142px);
`;

const ContainerContentBMS = styled.div<IContainerLoading>`
  height: calc(100vh - 62px);
  width: 100%;
  padding-left: ${(props) =>
    props.isShowSummary && props.isSidebarExpand
      ? '630px'
      : props.isShowSummary && !props.isSidebarExpand
      ? '380px;'
      : !props.isShowSummary && props.isSidebarExpand
      ? '250px'
      : !props.isShowSummary && !props.isSidebarExpand
      ? '0px'
      : '0'};
`;

const TitleDevice = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const DeviceWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const DeviceInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Flex = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
  align-items: center;

  span {
    font-size: 10px;
    color: ${(props) => props.theme.colors.grayDarken_Default};
  }
`;

const DataDevice = styled.div`
  span {
    color: ${(props) => props.theme.colors.grayDarken_Default};
    font-size: ${(props) => props.theme.fontSize.caption};
  }

  p {
    color: ${(props) => props.theme.colors.black};
    font-size: ${(props) => props.theme.fontSize.caption};
    margin-bottom: 0;
  }
`;

const AssetItem = styled.div`
  width: 100%;
  padding: 16px 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
`;

interface IMapWrapper extends IMapSummary {
  showSummary: boolean;
}

const ToggleSummary = styled.div<IMapWrapper>`
  position: absolute;
  transition: 3ms all ease;
  top: 7px;
  left: ${(props) =>
    props.showSummary
      ? props.isSidebarExpand
        ? '630px'
        : '450px'
      : props.isSidebarExpand
      ? '250px'
      : '70px'};
  z-index: 998;
  cursor: pointer;
`;
interface IMapSummary {
  isSidebarExpand: boolean;
}

const MapSummary = styled.div<IMapSummary>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 62px);
  width: 380px;
  transition: all 0.2s;
  position: absolute;
  top: 62px;
  z-index: 997;
  background-color: white;
  left: ${(props) => (props.isSidebarExpand ? '250px' : '70px')};
  border-right: thin solid lightgray;
`;

interface IContainerLoading extends IMapSummary {
  isShowSummary: boolean;
}

const ContainerLoading = styled.div<IContainerLoading>`
  background: ${(props) => props.theme.colors.grayLighten_Default};
  height: 100vh;
  width: 100%;
  padding-left: ${(props) =>
    props.isShowSummary && props.isSidebarExpand
      ? '630px'
      : props.isShowSummary && !props.isSidebarExpand
      ? '450px;'
      : !props.isShowSummary && props.isSidebarExpand
      ? '250px'
      : !props.isShowSummary && !props.isSidebarExpand
      ? '70px'
      : '0'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 10px;
    opacity: 0.5;
    text-align: center;
  }
`;

const SelectAppWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 7px 8px 7px 12px;
  align-items: center;
  border-radius: 100px;
  background-color: white;
  position: absolute;
  top: 10px;
  right: 90px;
  z-index: 998;
  cursor: pointer;
`;

const ProfileWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 998;
`;

export default HomeBMS;
