import { IResponseStatus, initialResponseStatus } from '../helpers/pagination';
import { ICompAuthStatus, initialCompAuthStatus } from './company.type';

export interface IRoleData {
  roleId: string;
  roleName: string;
  roleCreatedUserId: string;
  roleCreatedTime: string;
  roleUpdatedUserId: string;
  roleUpdatedTime: string;
}

export interface IUserData {
  userCreatedTime: string;
  userCreatedUserId: string;
  userId: string;
  userStatus: string;
  userUpdatedTime: string;
  userUpdatedUserId: string;
}

export interface IUserRoleData {
  usroId: string;
  usroUserId: string;
  usroRoleId: string;
  usroCreatedUserId: string;
  usroCreatedTime: string;
  usroUpdatedUserId: string;
  usroUpdatedTime: string;
  usroRole: IRoleData;
}

export interface IUserCompanyData {
  uscoId: string;
  uscoUserId: string;
  uscoCompId: string;
  uscoCreatedUserId: string;
  uscoCreatedTime: string;
  uscoUpdatedUserId: string;
  uscoUpdatedTime: string;
}

export interface ProfileProperties {
  firstName?: string;
  lastName?: string;
  login?: string;
  roles: IRoleData[];
}

export interface IPermissionData {
  prmsId: string;
  prmsName: string;
  prmsOperations: string[];
  prmsCreatedUserId: string;
  prmsCreatedTime: string;
  prmsUpdatedUserId: string;
  prmsUpdatedTime: string;
}

export interface IRolePermissionData {
  rperId: string;
  rperRoleId: string;
  rperPrmsId: string;
  rperOperations: string[];
  rperCreatedUserId: string;
  rperCreatedTime: string;
  rperUpdatedUserId: string;
  rperUpdatedTime: string;
  rperRole: IRoleData;
  rperPrms: IPermissionData;
}

export const initialProfile: ProfileProperties = {
  login: '',
  firstName: '',
  lastName: '',
  roles: [],
};

export const initialUser: IUserData = {
  userCreatedTime: '',
  userCreatedUserId: '',
  userId: '',
  userStatus: '',
  userUpdatedTime: '',
  userUpdatedUserId: '',
};

export const initialRole: IRoleData = {
  roleCreatedTime: '',
  roleCreatedUserId: '',
  roleId: '',
  roleName: '',
  roleUpdatedTime: '',
  roleUpdatedUserId: '',
};

export interface IUserStatus {
  access: { [T: string]: string[] };
  company: {
    active: ICompAuthStatus;
    available: {
      [T: string]: ICompAuthStatus;
    };
  };
  user: string;
  status: IResponseStatus;
}

export const initialUserStatus: IUserStatus = {
  access: {},
  company: {
    active: initialCompAuthStatus,
    available: {},
  },
  user: '',
  status: { ...initialResponseStatus },
};
