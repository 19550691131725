import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Table,
  TimePicker,
  Tooltip,
  message,
} from 'antd';
import {
  IReportTargetAsset,
  IReportTargetAssetRequest,
  IReportTemplate,
} from '../../../types/masterReport.type';
import useAuth from '../../../hooks/useAuth';
import { useEffect, useState } from 'react';
import {
  MasterReportTemplateSvc,
  MasterTargetAssetSvc,
  MasterTargetHeaderSvc,
} from '../../../services/MasterReportSvc';
import { GroupActionButton, GroupFormItem } from '../../groupCompany';
import useAsset from '../../../hooks/useAsset';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import { IGroupTargetByTemplate } from './masterTargetAsset';

interface Props {
  isOpen: boolean;
  data?: IGroupTargetByTemplate;
  afterSubmit: () => void;
  compId: string;
  assets: any[];
}

const frequencies = [
  {
    key: '1',
    label: 'HOURLY',
  },
  {
    key: '2',
    label: 'DAILY',
  },
  {
    key: '3',
    label: 'WEEKLY',
  },
  {
    key: '4',
    label: 'MONTHLY',
  },
];

interface ITargetHeader {
  key: string;
  mrtaId?: string;
  mrtaMrthId: string;
  mrtaMrthEmail: string;
  checked: boolean;
}

const ModalMasterTargetAsset: React.FC<Props> = ({
  isOpen,
  data,
  afterSubmit,
  compId,
  assets,
}) => {
  const { handlePermission } = useAuth();
  const [form] = Form.useForm<IReportTargetAssetRequest>();
  const [formEmail] = Form.useForm<{ newEmail: string }>();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const [emailTemplates, setEmailTemplates] = useState<IReportTemplate[]>([]);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState<boolean>(false);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [targetHeaders, setTargetHeaders] = useState<ITargetHeader[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    if (isOpen) {
      const fetchCurrentTemplates = async () => {
        setIsLoadingTemplate(true);
        try {
          /**
           * Get report template based on company id user yg login
           */
          const results = await MasterReportTemplateSvc.getAllMasterReport({
            page: 1,
            max: 100,
            findField: 'mrtpCompId',
            findValue: compId,
          });
          setEmailTemplates(results.data.data);
        } catch (error) {
          message.error('Error: Failed fetch data report template');
          console.error(error);
        } finally {
          setIsLoadingTemplate(false);
        }
      };

      fetchCurrentTemplates();
    }
  }, [isOpen]);

  async function create() {
    try {
      setIsLoadingSubmit(true);
      const values = await form.validateFields();
      const formData = {
        mrtaMassId: values.mrtaMassId,
        mrtaMrthId: values.mrtaMrthId,
        mrtaMrtpId: values.mrtaMrtpId,
        mrtaFrequencyType: values.mrtaFrequencyType,
        mrtaTime: moment(values.mrtaTime).format('HH:mm:ss'),
        mrtaTimeFrom: moment(values.mrtaTimeFrom).format('HH:mm:ss'),
        mrtaTimeTo: moment(values.mrtaTimeTo).format('HH:mm:ss'),
      };

      targetHeaders.forEach(async (item) => {
        // process selected target header only
        const found = selectedRowKeys.find((key) => key === item.key);
        if (found) {
          // create new target header for new email
          if (item.mrtaMrthEmail === item.mrtaMrthId) {
            const resultNewTargetHeader =
              await MasterTargetHeaderSvc.addTargetHeader({
                mrthEmail: item.mrtaMrthEmail,
                mrthCompId: compId,
              });
            // create new target asset after add new target header
            await MasterTargetAssetSvc.addTargetAsset({
              ...formData,
              mrtaMrthId: resultNewTargetHeader.data.id,
            });
          } else {
            // create new target asset for existing email
            await MasterTargetAssetSvc.addTargetAsset({
              ...formData,
              mrtaMrthId: item.mrtaMrthId,
            });
          }
        }
      });

      message.success('Success add data');
      handleReset();
    } catch (error: any) {
      if (error.errorFields && error.errorFields.length > 0) {
        message.error('Error Validation: Check your input');
      } else {
        message.error('Error: ' + error);
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  async function update() {
    try {
      if (selectedRowKeys.length === 0) {
        message.warn('You must atleast selected one email target');
        return;
      }

      setIsLoadingSubmit(true);
      const values = await form.validateFields();
      const formData = {
        mrtaMassId: values.mrtaMassId,
        mrtaMrthId: values.mrtaMrthId,
        mrtaMrtpId: values.mrtaMrtpId,
        mrtaFrequencyType: values.mrtaFrequencyType,
        mrtaTime: moment(values.mrtaTime).format('HH:mm:ss'),
        mrtaTimeFrom: moment(values.mrtaTimeFrom).format('HH:mm:ss'),
        mrtaTimeTo: moment(values.mrtaTimeTo).format('HH:mm:ss'),
      };

      // delete unused target header when not included in selectedRowKeys
      const deletedTargetAsset = data?.datas.filter((data) => {
        return !selectedRowKeys.includes(data.mrtaMrthId);
      });

      if (deletedTargetAsset && deletedTargetAsset.length > 0) {
        for await (const item of deletedTargetAsset) {
          await MasterTargetAssetSvc.deleteTargetAsset(item.mrtaId);
        }
      }

      // create new email target
      const newEmailTargetAsset = targetHeaders.filter(
        (item) => item.mrtaMrthEmail === item.mrtaMrthId
      );

      if (newEmailTargetAsset && newEmailTargetAsset.length > 0) {
        for await (const item of newEmailTargetAsset) {
          // create new target header
          const resultNewTargetHeader =
            await MasterTargetHeaderSvc.addTargetHeader({
              mrthEmail: item.mrtaMrthEmail,
              mrthCompId: compId,
            });
          // create new target asset
          await MasterTargetAssetSvc.addTargetAsset({
            ...formData,
            mrtaMrthId: resultNewTargetHeader.data.id,
          });
        }
      }

      // update existing target asset
      const deletedMrthIds = deletedTargetAsset?.map((item) => item.mrtaMrthId);
      const foundExistingTargetHeader = data?.datas.filter(
        (item) => !deletedMrthIds?.includes(item.mrtaMrthId)
      );

      if (foundExistingTargetHeader && foundExistingTargetHeader.length > 0) {
        for await (const item of foundExistingTargetHeader) {
          await MasterTargetAssetSvc.updateTargetAsset({
            ...formData,
            mrtaMrthId: item.mrtaMrthId,
            mrtaId: item.mrtaId,
          });
        }
      }

      // add new existing target asset
      const existingTargetHeaders = data?.datas.map((a) => a.mrtaMrthId);
      const newExistingTargetHeaders = selectedRowKeys
        .map((item) => Number(item))
        .filter((item) => !isNaN(item))
        .filter((item) => existingTargetHeaders?.indexOf(String(item)) === -1);

      for await (const item of newExistingTargetHeaders) {
        await MasterTargetAssetSvc.addTargetAsset({
          ...formData,
          mrtaMrthId: String(item),
        });
      }

      message.success('Success edit data');
      handleReset();
    } catch (error: any) {
      if (error.errorFields && error.errorFields.length > 0) {
        message.error('Error Validation: Check your input');
      } else {
        message.error('Error: ' + error);
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  async function handleOnSubmit() {
    if (data && data.datas && data.datas.length > 0) {
      await update();
    } else {
      await create();
    }
  }

  function handleReset() {
    form.resetFields();
    afterSubmit();
  }

  useEffect(() => {
    if (data) {
      const firstData = data.datas[0];
      const currentData: any = {
        mrtaId: firstData.mrtaId,
        mrtaMrtpId: firstData.mrtaMrtpId,
        mrtaMassId: firstData.mrtaMassId,
        mrtaTime: moment(firstData.mrtaTime, 'HH:mm:ss'),
        mrtaTimeFrom: moment(firstData.mrtaTimeFrom, 'HH:mm:ss'),
        mrtaTimeTo: moment(firstData.mrtaTimeTo, 'HH:mm:ss'),
        mrtaFrequencyType: firstData.mrtaFrequencyType,
        mrtaDayOfWeek: firstData.mrtaDayOfWeek,
        mrtaDateOfMonth: firstData.mrtaDateOfMonth,
        mrtaDataThresholdMin: firstData.mrtaDataThresholdMin,
      };
      setSelectedRowKeys(data.datas.map((item) => item.mrtaMrthId));
      form.setFieldsValue(currentData);
    }
  }, [data]);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };

  async function fetchTargetHeaders() {
    try {
      const response = await MasterTargetHeaderSvc.getAllTargetHeader({
        page: 1,
        max: 100,
        findField: 'mrthCompId',
        findValue: compId,
      });

      setTargetHeaders(
        response.data.data.map((item) => ({
          key: item.mrthId,
          mrtaMrthId: item.mrthId,
          mrtaMrthEmail: item.mrthEmail,
          checked: false,
        }))
      );
    } catch (error) {
      console.error('failed fetch target headers', error);
    }
  }

  useEffect(() => {
    if (compId) {
      fetchTargetHeaders();
    }
  }, [compId]);

  async function handleDeleteTargetAsset(mrtaId: string) {
    try {
      /**
       * Function delete report target asset
       * 1. Hanya hapus data target asset tanpa hapus data master target header
       */
      await MasterTargetAssetSvc.deleteTargetAsset(mrtaId);
    } catch (error) {
      console.error('failed delete target asset', error);
    }
  }

  function reConstructTargetHeaders(params: { emailToRemoved: string }) {
    setTargetHeaders((current) =>
      current
        .filter((item) => item.mrtaMrthEmail !== params.emailToRemoved)
        .map((item) => item)
    );
  }

  const confirmDelete = (mrthEmail: string, mrtaId?: string) => {
    Modal.confirm({
      title: 'Delete Report Target',
      content: `Are you sure want to delete "${mrthEmail}"?`,
      okText: 'Yes, Sure',
      cancelText: 'Cancel',
      onOk: async () => {
        if (mrtaId) {
          await handleDeleteTargetAsset(mrtaId);
        }
      },
      afterClose: () => {
        reConstructTargetHeaders({ emailToRemoved: mrthEmail });
        // setQuery((currQuery) => ({
        //   ...currQuery,
        //   page: 1,
        // }));
      },
    });
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  async function handleAddNewEmail() {
    const values = await formEmail.validateFields();
    if (values.newEmail) {
      setTargetHeaders((current) => [
        {
          key: values.newEmail,
          mrtaMrthId: values.newEmail,
          mrtaMrthEmail: values.newEmail,
          checked: false,
        },
        ...current,
      ]);

      formEmail.resetFields();
    }
  }

  function handleMenuClick(item: any, data: ITargetHeader) {
    if (item === 'delete') {
      if (data.mrtaMrthEmail === data.mrtaMrthId) {
        setTargetHeaders((current) =>
          current
            .filter((item) => item.mrtaMrthEmail !== data.mrtaMrthEmail)
            .map((item) => item)
        );
      }
    }
  }

  const columns: ColumnsType<ITargetHeader> = [
    {
      title: 'Email',
      dataIndex: 'mrtaMrthEmail',
    },
    {
      title: 'Email',
      dataIndex: 'mrtaMrthId',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record: ITargetHeader) => {
        return (
          record.mrtaMrthEmail === record.mrtaMrthId && (
            <GroupActionButton>
              <Tooltip title='prompt text'>
                <Button
                  onClick={() => handleMenuClick('delete', record)}
                  size='small'
                  danger
                  value='delete'
                  disabled={record.mrtaMrthEmail !== record.mrtaMrthId}
                >
                  Delete
                </Button>
              </Tooltip>
            </GroupActionButton>
          )
        );
      },
    },
  ];

  return (
    <Modal
      width={'45%'}
      title={
        data && data.datas && data.datas.length > 0
          ? 'Edit Target Asset'
          : 'Add Target Asset'
      }
      open={isOpen}
      onCancel={handleReset}
      className='custom-modal'
      footer={[
        <Button
          style={{ background: '#FFFFFF', color: '#000000' }}
          onClick={handleReset}
        >
          Cancel
        </Button>,
        <Button
          loading={isLoadingSubmit}
          disabled={
            (!handlePermission(['master_report_target_header'], 'create') &&
              !handlePermission(['master_report_target_header'], 'update')) ||
            disabledSave ||
            selectedRowKeys.length === 0
          }
          onClick={handleOnSubmit}
          type='primary'
        >
          {data && data.datas && data.datas.length > 0
            ? `Update`
            : `Create (${selectedRowKeys.length}) Target Email`}
        </Button>,
      ]}
    >
      <Form layout='vertical' form={form} onFieldsChange={handleFormChange}>
        <GroupFormItem child={2}>
          <Form.Item
            required
            rules={[
              {
                required: true,
                message: `Email template required.`,
              },
            ]}
            label='Email Template'
            name='mrtaMrtpId'
          >
            <Select
              disabled={data && data.datas && data.datas.length > 0}
              loading={isLoadingTemplate}
              placeholder='Select Email Template'
            >
              {emailTemplates.map((x) => {
                return (
                  <Select.Option value={x.mrtpId}>{x.mrtpName}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label='Asset'
            required
            rules={[
              {
                required: true,
                message: `Asset required.`,
              },
            ]}
            name='mrtaMassId'
          >
            <Select
              disabled={data && data.datas && data.datas.length > 0}
              placeholder='Select Asset'
            >
              {assets.map((x) => {
                return <Select.Option value={x.value}>{x.label}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </GroupFormItem>

        <GroupFormItem child={2}>
          <Form.Item
            required
            rules={[
              {
                required: true,
                message: `Sending Time template required.`,
              },
            ]}
            label='Sending Time'
            name='mrtaTime'
          >
            <TimePicker style={{ width: '100%' }} format={'HH:mm:ss'} />
          </Form.Item>
          <GroupFormItem child={2}>
            <Form.Item
              required
              rules={[
                {
                  required: true,
                  message: `Start time required.`,
                },
              ]}
              label='Time From'
              name='mrtaTimeFrom'
            >
              <TimePicker style={{ width: '100%' }} format={'HH:mm:ss'} />
            </Form.Item>
            <Form.Item
              required
              rules={[
                {
                  required: true,
                  message: `End time required.`,
                },
              ]}
              label='Time To'
              name='mrtaTimeTo'
            >
              <TimePicker style={{ width: '100%' }} format={'HH:mm:ss'} />
            </Form.Item>
          </GroupFormItem>
        </GroupFormItem>

        <GroupFormItem child={2}>
          <Form.Item
            required
            rules={[
              {
                required: true,
                message: `Email template required.`,
              },
            ]}
            label='Frequency'
            name='mrtaFrequencyType'
          >
            <Select placeholder='Select Frequency'>
              {frequencies.map((x) => {
                return <Select.Option value={x.key}>{x.label}</Select.Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label='Day of Week' name='mrtaDayOfWeek'>
            <Input placeholder='Enter Day of Week' />
          </Form.Item>
        </GroupFormItem>

        <GroupFormItem child={2}>
          <Form.Item label='Date of Month' name='mrtaDateOfMonth'>
            <Input placeholder='Enter Date of Month' />
          </Form.Item>
          <Form.Item label='Data Threshold Min' name='mrtaDataThresholdMin'>
            <Input placeholder='Enter Data Threshold Min' />
          </Form.Item>
        </GroupFormItem>
      </Form>

      <Divider style={{ margin: '10px 0' }} />

      <div>
        <GroupFormItem child={1} style={{ marginBottom: 5 }}>
          <span>Add New Target</span>
        </GroupFormItem>
        <Form
          layout='horizontal'
          form={formEmail}
          onFieldsChange={handleFormChange}
          onFinish={handleAddNewEmail}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Form.Item label='Email' name='newEmail' style={{ flex: 1 }}>
            <Input placeholder='Enter new email' />
          </Form.Item>
          <Button htmlType='submit' style={{ marginLeft: 10 }} type='primary'>
            Add Email
          </Button>
        </Form>

        <Divider style={{ margin: '10px 0' }} />

        <GroupFormItem child={2} style={{ marginBottom: 5 }}>
          <span>Email Targets</span>
          <span style={{ textAlign: 'right' }}>
            Total Selected {selectedRowKeys.length}
          </span>
        </GroupFormItem>

        <Table
          rowSelection={rowSelection}
          loading={isLoadingTable}
          columns={columns}
          size='small'
          dataSource={targetHeaders}
          pagination={false}
        />
      </div>
    </Modal>
  );
};

export default ModalMasterTargetAsset;
