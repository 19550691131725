import React, { useState, useEffect } from 'react';
import AuthContext from './AuthContext';
import { AuthState, EAuthStatus } from '../types/auth.type';
import {
  IUserStatus,
  initialProfile,
  initialUser,
  initialUserStatus,
} from '../types/user.type';
import { getToken } from '../helpers/auth';
import { message, Spin } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import AuthSvc from '../services/AuthSvc';
import useCompany from '../hooks/useCompany';

type Props = {
  children?: React.ReactNode;
};

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState<AuthState>({
    status: EAuthStatus.LOGGED_OUT,
    user: initialUser,
    profile: initialProfile,
    loginData: initialUserStatus,
    access: {},
    // role: ''
  });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [logData, setLogData] = useState<any[]>([]);
  const { setCurrentActiveCompany, setAvailableCompaniesRaw } = useCompany();

  async function handleSetCompanyAuth(status: IUserStatus) {
    if (status && status.company) {
      setAvailableCompaniesRaw(status.company.available);
      if (typeof status.company.active === 'number') {
        setCurrentActiveCompany({
          compId: (status.company.active as number).toString(),
          compGrcpId: status.company.available[status.company.active].compGrcpId,
        });
      } else {
        setCurrentActiveCompany({
          compId: status.company.active.compId,
          compGrcpId: status.company.active.compGrcpId,
        });
      }
    }
  }

  useEffect(() => {
    const token = getToken();

    const loginData = async () => {
      const result = await AuthSvc.getUserStatus();
      await handleSetCompanyAuth(result.data);
      return result;
    };

    if (!token) {
      setIsLoaded(true);
      return;
    }

    loginData()
      .then((result: any) => {
        setAuth({
          ...auth,
          user: initialUser,
          profile: initialProfile,
          status: EAuthStatus.LOGGED_IN,
          loginData: result.data,
          access: result.data.access,
        });
      })
      .catch((err) => message.error(getErrorMessage(err)))
      .finally(() => setIsLoaded(true));
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {isLoaded ? (
        children
      ) : (
        <Spin size='large'>
          <div style={{ width: '100vw', height: '100vh' }} />
        </Spin>
      )}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
