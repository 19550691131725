import { createGlobalState } from "react-hooks-global-state";

type BreadcrumbItem = {
  field: string;
  value: string;
  label: string;
};
type State = {
  breadcrumbDetails: BreadcrumbItem[];
  customTitle?: string;
};

const initialState: State = {
  breadcrumbDetails: [],
  customTitle: undefined,
};
const { useGlobalState } = createGlobalState(initialState);

export default function useDetailBreadcrumbs() {
  const [breadcrumbDetails, setBreadcrumbDetails] =
    useGlobalState("breadcrumbDetails");
  const [customTitle, setCustomTitle] = useGlobalState("customTitle");

  return {
    breadcrumbDetails,
    setBreadcrumbDetails,
    customTitle,
    setCustomTitle,
  };
}
