import styled from 'styled-components';
import useAsset from '../hooks/useAsset';

interface IProps {
  isHome?: boolean;
}

const ToggleDataSource: React.FC<IProps> = (props) => {
  const { currentDataSource, setCurrentSource } = useAsset();

  return (
    <SelectDataSourceWrapper isHome={props.isHome}>
      <DataSourceItem
        active={currentDataSource === 'gsm'}
        onClick={() => setCurrentSource('gsm')}
        currDataSource={currentDataSource}
        isHome={props.isHome}
      >
        <img
          alt='icon-gsm'
          src={
            currentDataSource === 'gsm'
              ? '/images/icon-gsm-light.svg'
              : '/images/icon-gsm.svg'
          }
        />
        <span>GSM</span>
      </DataSourceItem>
      <DataSourceItem
        active={currentDataSource === 'satellite'}
        onClick={() => setCurrentSource('satellite')}
        currDataSource={currentDataSource}
        isHome={props.isHome}
      >
        <img
          alt='icon-satellite'
          src={
            currentDataSource === 'satellite'
              ? '/images/icon-satellite-light.svg'
              : '/images/icon-satellite.svg'
          }
        />
        <span>Satellite</span>
      </DataSourceItem>
    </SelectDataSourceWrapper>
  );
};

interface IDataSourceWrapper {
  isHome?: boolean;
}

export const SelectDataSourceWrapper = styled.div<IDataSourceWrapper>`
  position: ${(props) => (props.isHome ? 'absolute' : 'relative')};
  top: ${(props) => (props.isHome ? '9px' : '0')};
  right: ${(props) => (props.isHome ? '325px' : '0')};
  border-radius: 100px;
  border: 1px solid #e8ecf3;
  background: #d2d8e1;
  gap: 4px;
  padding: 1px;
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${(props) => (props.isHome ? '29px' : '38px')};
  z-index: 998;
  cursor: pointer;
`;

interface IDataSourceItem {
  active: boolean;
  currDataSource?: string;
  isHome?: boolean;
}

export const DataSourceItem = styled.div<IDataSourceItem>`
  border: ${(props) => (props.active ? '1px solid #b7bec9' : 'none')};
  background-color: ${(props) =>
    props.active
      ? props.currDataSource === 'gsm'
        ? '#bd1f37'
        : '#FF842C'
      : 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 4px 12px 4px 8px;
  cursor: pointer;
  span {
    color: ${(props) => (props.active ? 'white' : 'black')};
    font-size: 13px;
    line-height: 1.5;
  }
  img {
    width: 20px;
  }
`;

export default ToggleDataSource;
