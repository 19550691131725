import moment from 'moment';
import styled from 'styled-components';

interface IProps {
  currentTime: any;
  page: any;
  lastPage: any;
}

const ReportDailyFooter: React.FC<IProps> = (props) => {
  return (
    <RPFooterWrapper>
      <div>Report generated at : {props.currentTime}</div>
      <div>
        {props.page}/{props.lastPage}
      </div>
    </RPFooterWrapper>
  );
};

const RPFooterWrapper = styled.div`
  border-top: 3px solid #012b65;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  /* position: absolute; */
  color: black;
  width: 100%;
  bottom: 30px;
`;

export default ReportDailyFooter;
