import { ReactNode } from 'react';
import styled from 'styled-components';
import ReportDailyHeader from './ReportDailyHeader';
import ReportDailyFooter from './ReprotDailyFooter';

interface IProps {
  massName: string;
  start: any;
  end: any;
  children: ReactNode;
  currentTime: any;
  page: number;
  lastPage: number;
  title: string;
}

const ReportDailyPage: React.FC<IProps> = (props) => {
  return (
    <Page>
      <ReportDailyHeader
        massName={props.massName}
        start={props.start}
        end={props.end}
      />

      <Content>
        <h3>{props.title}</h3>
        {props.children}
      </Content>

      <ReportDailyFooter
        currentTime={props.currentTime}
        page={props.page}
        lastPage={props.lastPage}
      />
    </Page>
  );
};

export const Page = styled.div`
  position: relative;
  background-color: white;
  border-bottom: 1px solid white;
  width: 297mm !important;
  height: 219mm !important;
  /* height: 230mm !important; */
  margin: 0 auto;
  page-break-after: always;
`;

const Content = styled.div`
  height: 165mm !important;
  background-color: white !important;
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
`;

export default ReportDailyPage;
