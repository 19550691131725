import { AxiosResponse } from 'axios';
import { DefaultResponseListProps } from '../types/config.type';
import { IOperationDetail, IOperationHeader } from '../types/operationMode.type';
import { httpRequest } from '../helpers/api';

const MasterOperatrionModeSvc = {
  // Header
  getAllMasterHeader(
    params?: any
  ): Promise<AxiosResponse<DefaultResponseListProps<IOperationHeader>>> {
    return httpRequest.get('/master/Rpm_Operation_Header', {
      params,
    });
  },

  addMasterHeader(data: any): Promise<AxiosResponse<any>> {
    return httpRequest.post('/master/Rpm_Operation_Header', data);
  },

  deleteMasterHeader(mrohId: string): Promise<AxiosResponse<any>> {
    return httpRequest.delete(`/master/Rpm_Operation_Header/${mrohId}`);
  },

  updateMasterHeader(mrohId: string, data: any): Promise<AxiosResponse<any>> {
    return httpRequest.put(`/master/Rpm_Operation_Header/${mrohId}`, data);
  },

  getMasterHeader(mrohId: string): Promise<AxiosResponse<any>> {
    return httpRequest.get(`/master/Rpm_Operation_Header/${mrohId}`);
  },

  // Detail
  addMasterDetail(data: any): Promise<AxiosResponse<any>> {
    return httpRequest.post('/master/Rpm_Operation_Detail', data);
  },

  deleteMasterDetail(mrodId: string): Promise<AxiosResponse<any>> {
    return httpRequest.delete(`/master/Rpm_Operation_Detail/${mrodId}`);
  },

  updateMasterDetail(mrodId: string, data: any): Promise<AxiosResponse<any>> {
    return httpRequest.put(`/master/Rpm_Operation_Detail/${mrodId}`, data);
  },

  getMasterDetail(mrodId: string): Promise<AxiosResponse<any>> {
    return httpRequest.get(`/master/Rpm_Operation_Detail/${mrodId}`);
  },

  getAllMasterDetail(
    params?: any
  ): Promise<AxiosResponse<DefaultResponseListProps<IOperationDetail>>> {
    return httpRequest.get('/master/Rpm_Operation_Detail', {
      params,
    });
  },
};

export default MasterOperatrionModeSvc;
